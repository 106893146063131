import React from "react";
import {
  redirect,
  useLoaderData,
  useLocation,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../generic-error";
import { getResources } from "../../../service/resource/index.ts";
import { getNewsCount, getNewsList } from "../../../service/news/get.ts";
import Resource from "../../../dtos/resource/index.ts";
import { useEffect, useState } from "react";
import NewsSummary from "../../../dtos/news/summary.ts";
import ResourceDisplay from "../../../components/resource-display/index.jsx";
import NewsSummaryCard from "../../../components/news-summary-card/index.tsx";
import { IMAGE_HOST } from "../../../api/config.ts";
import { Pagination, PaginationItem } from "@mui/material";
import STYLES_CONSTANTS from "../../../styles/index.js";
import useWindowDimensions from "../../../hooks/useWindowDimensions.ts";
import SearchBox from "../../../components/search-box/index.tsx";

const DEFAULT_NEWS_PER_PAGE = 8;
const RECORD_HISTORY_ON_PAGE_CHANGE = false;

const resourceKeys = {
  cover: "news-list-cover",
  pageTitle: "news-list-title",
  pageSubtitle: "news-list-subtitle",
  backgroundImage: "image-paper-white",
  aboutLinie: "about-nonews-liniealbastra",
  aboutPeople: "about-nonews-people",
};

type LoaderType = [
  [number, number, string | undefined],
  Resource | Resource[],
  number,
  NewsSummary[]
];

export async function loader({ request }): Promise<LoaderType> {
  const url = new URL(request.url);

  const pageString = url.searchParams.get("page");
  let page: number;
  if (pageString === null) page = 1;
  else page = parseInt(pageString!);
  if (isNaN(page)) redirect("/not-found");

  const sizeString = url.searchParams.get("size");
  let size: number;
  if (sizeString === null) size = DEFAULT_NEWS_PER_PAGE;
  else size = parseInt(sizeString);
  if (isNaN(size)) redirect("/not-found");

  const searchString = url.searchParams.get("search");
  const search = searchString === null ? undefined : searchString;

  const resourcesPromise = getResources(Object.values(resourceKeys));

  try {
    const newsListPromise = getNewsList(page, size, search);
    const totalNewsCountPromise = getNewsCount(search);

    return [
      [page, size, search],
      await resourcesPromise,
      await totalNewsCountPromise,
      await newsListPromise,
    ];
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return [[page, size, search], await resourcesPromise, 0, []];
    } else {
      console.error("Error loading news list:", error);
      throw error;
    }
  }
}

export function Component() {
  const [
    [currentPageNumber, requestedPageSize, searchQuerry],
    resourcesArray,
    totalNewsCount,
    newsList,
  ] = useLoaderData() as LoaderType;
  const [aboutPeople, setAboutPeople] = useState<Resource | null>(null);
  const [aboutLinie, setAboutLinie] = useState<Resource | null>(null);
  const [cover, setCover] = useState<Resource | null>(null);
  const [pageTitle, setPageTitle] = useState<Resource | null>(null);
  const [pageSubtitle, setPageSubtitle] = useState<Resource | null>(null);
  const [backgroundImage, setBackgroundImage] = useState<Resource | null>(null);
  const [pageCount, setPageCount] = useState<number>(0);

  const windowsDimensions = useWindowDimensions();

  useEffect(() => {
    if (
      resourcesArray === undefined ||
      resourcesArray === null ||
      !Array.isArray(resourcesArray)
    ) {
      setCover(null);
      setPageTitle(null);
      setAboutLinie(null);
      setAboutPeople(null);
      return;
    }

    resourcesArray.forEach((resource) => {
      if (resource.id === resourceKeys.cover) setCover(resource);
      if (resource.id === resourceKeys.pageTitle) setPageTitle(resource);
      if (resource.id === resourceKeys.pageSubtitle) setPageSubtitle(resource);
      if (resource.id === resourceKeys.backgroundImage)
        setBackgroundImage(resource);
      if (resource.id === resourceKeys.aboutLinie) setAboutLinie(resource);
      if (resource.id === resourceKeys.aboutPeople) setAboutPeople(resource);
    });
  }, [resourcesArray]);

  useEffect(
    () =>
      setPageCount(
        Math.floor(totalNewsCount / requestedPageSize) +
          (totalNewsCount % requestedPageSize === 0 ? 0 : 1)
      ),
    [totalNewsCount, requestedPageSize]
  );

  const navigate = useNavigate();
  const location = useLocation();

  function handlePaginationChange(
    event: React.ChangeEvent<unknown>,
    value: number
  ) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", value.toString());

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: RECORD_HISTORY_ON_PAGE_CHANGE,
    });
  }

  function handleSearchSubmit(searchQuery: string) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("search", searchQuery.toString().trim());
    searchParams.set("page", "1");

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: RECORD_HISTORY_ON_PAGE_CHANGE,
    });
  }

  // if (newsList.length === 0) {
  //   return (
  //     <div id="page-nonews">
  //     <div className="big-container">
  //       <h2 className="text-container">Niciun rezultat nu a fost gasit!</h2>
  //       <div className="line-container"><ResourceDisplay resource={aboutLinie}/></div>
  //     </div>
  //     <div className="image-container"><ResourceDisplay resource={aboutPeople}/></div>
  //     </div>

  //   )};
  return (
    <div
      id="page-news"
      style={
        (backgroundImage
          ? {
              "--background-image-url":
                "url(" + IMAGE_HOST + backgroundImage.value + ")",
            }
          : {}) as React.CSSProperties
      }
    >
      <section className="header content-container">
        <ResourceDisplay resource={cover} className="cover" />
        <ResourceDisplay
          resource={pageTitle}
          wrapper="h1"
          className="page-title"
        />
        <ResourceDisplay resource={pageSubtitle} className="page-subtitle" />
        <SearchBox
          onSearch={handleSearchSubmit}
          initialValue={searchQuerry}
          className="search"
        />
      </section>
      <section className="news content-container">
        {newsList.length === 0 ? (
          <div id="page-nonews">
            <div className="big-container">
              <h2 className="text-container">
                Niciun rezultat nu a fost găsit!
              </h2>
              <div className="line-container">
                <ResourceDisplay resource={aboutLinie} />
              </div>
            </div>
            <div className="image-container">
              <ResourceDisplay resource={aboutPeople} />
            </div>
          </div>
        ) : (
          <>
            <Pagination
              className="pagination"
              onChange={handlePaginationChange}
              renderItem={(item) => (
                <PaginationItem
                  sx={{
                    fontFamily: STYLES_CONSTANTS["font-family"],
                    fontWeight: item.selected ? "bold" : "regular",
                    color: "white",
                  }}
                  {...item}
                />
              )}
              count={pageCount}
              page={currentPageNumber}
              defaultPage={1}
              siblingCount={windowsDimensions.width > 600 ? 1 : 0}
              boundaryCount={windowsDimensions.width > 600 ? 1 : 0}
              size="large"
              showFirstButton={windowsDimensions.width > 400}
              showLastButton={windowsDimensions.width > 400}
            />
            {newsList.map((newsSummary) => (
              <NewsSummaryCard newsSummary={newsSummary} key={newsSummary.id} />
            ))}
            <Pagination
              className="pagination"
              onChange={handlePaginationChange}
              renderItem={(item) => (
                <PaginationItem
                  sx={{
                    fontFamily: STYLES_CONSTANTS["font-family"],
                    fontWeight: item.selected ? "bold" : "regular",
                    color: item.selected
                      ? "var(--color-fasmr-blue)"
                      : "var(--color-dark)",
                  }}
                  {...item}
                />
              )}
              count={pageCount}
              page={currentPageNumber}
              defaultPage={1}
              siblingCount={windowsDimensions.width > 600 ? 1 : 0}
              boundaryCount={windowsDimensions.width > 600 ? 1 : 0}
              size="large"
              showFirstButton={windowsDimensions.width > 400}
              showLastButton={windowsDimensions.width > 400}
            />
          </>
        )}
      </section>
    </div>
  );
}

Component.displayName = "page-noutati";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-noutati-error";
