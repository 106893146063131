import Persongroup from "./persongroup.ts";

class Person {
  id: string;
  name: string;
  position: string;
  position_short?: string;
  email: string;
  profileLocation: string;
  description?:string;
  persongroups: Persongroup[];

  constructor(
    id: string,
    name: string,
    position: string,
    email: string,
    profileUri: string,
    imageHost: string,
    description?:string,
    persongroups?: Persongroup[],
    position_short?: string
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.position = position;
    this.profileLocation = imageHost + profileUri;
    this.description=description;
    this.persongroups =
      persongroups && persongroups !== undefined && persongroups !== null
        ? persongroups
        : [];
    this.position_short =
      position_short &&
      position_short !== undefined &&
      position_short !== null &&
      position_short.length > 0
        ? position_short
        : position;
  }
}

export default Person;
