import { useEffect, useState } from "react";
import { getResources } from "../../../service/resource/index.ts";
import GenericErrorDisplay from "../../generic-error/index.jsx";
import SectionDescriere from "./section-descriere";
import SectionIstoric from "./section-istoric";
import SectionStatutRoi from "./section-statut-roi";
import SectionStructura from "./section-structura";
import "./styles.css";
import { useLoaderData, useRouteError } from "react-router-dom";
import { getPersonGroup } from "../../../service/person/index.ts";
import { getStructureGroup } from "../../../service/structure/index.ts";
import SectionAsociatii from "./section-asociatii/index.jsx";
import StructureGroup from "../../../dtos/structure/group.ts";
import ResourceDisplay from "../../../components/resource-display/index.jsx";

const resourceKeys = {
  pageTitle: "about-index-description-title",
  descriptionText: "about-index-description-text",
  finalImage: "about-index-final-element",
  logoFasmrSimpleBlue: "logo-fasmr-simple-blue",

  historyTitle: "about-index-history-title",
  historyText: "about-index-history-text",

  documentsCover: "about-index-documents-picture",
  documentsFirstText: "about-index-documents-first-text",

  documentsStatutTitle: "about-index-documents-statut-title",
  documentsStatutText: "about-index-documents-statut-text",
  documentsStatutIcon: "about-index-documents-statut-icon",

  documentsRoiTitle: "about-index-documents-roi-title",
  documentsRoiText: "about-index-documents-roi-text",
  documentsRoiIcon: "about-index-documents-roi-icon",

  structureTitle: "about-index-structure-title",

  structureCdTitle: "about-index-structure-cd-title",
  structureCdText: "about-index-structure-cd-text",

  structureCommitteesTitle: "about-index-structure-committees-title",
  structureCommitteesText: "about-index-structure-committees-text",

  structureDivisionsTitle: "about-index-structure-divisions-title",
  structureDivisionsText: "about-index-structure-divisions-text",

  structureProjectsTitle: "about-index-structure-projects-title",
  structureProjectsText: "about-index-structure-projects-text",

  membersTitle: "about-index-members-title",
  membersText: "about-index-members-text",
  membersFullTitle: "about-index-members-full-title",
  membersObsTitle: "about-index-members-obs-title",
};

export async function loader({ request }) {
  const resources = getResources(Object.values(resourceKeys));
  const cdPeople = getPersonGroup("cd");
  const committees = getStructureGroup(StructureGroup.committee);
  const divisions = getStructureGroup(StructureGroup.division);
  const projects = getStructureGroup(StructureGroup.project);
  const membersFull = getStructureGroup(StructureGroup["member-full"]);
  const membersObs = getStructureGroup(StructureGroup["member-obs"]);

  return [
    await resources,
    await cdPeople,
    await committees,
    await divisions,
    await projects,
    await membersFull,
    await membersObs,
  ];
}

export function Component() {
  const [
    resourcesArray,
    cdPeople,
    committees,
    divisions,
    projects,
    membersFull,
    membersObs,
  ] = useLoaderData();
  const [pageTitle, setPageTitle] = useState(null);
  const [descriptionText, setDescriptionText] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [logoFasmrSimpleBlue, setLogoFasmrSimpleBlue] = useState(null);

  const [historyTitle, setHistoryTitle] = useState(null);
  const [historyText, setHistoryText] = useState(null);

  const [documentsCover, setDocumentsCover] = useState(null);
  const [documentsFirstText, setDocumentsFirstText] = useState(null);

  const [documentsStatutTitle, setDocumentsStatutTitle] = useState(null);
  const [documentsStatutText, setDocumentsStatutText] = useState(null);
  const [documentsStatutIcon, setDocumentsStatutIcon] = useState(null);

  const [documentsRoiTitle, setDocumentsRoiTitle] = useState(null);
  const [documentsRoiText, setDocumentsRoiText] = useState(null);
  const [documentsRoiIcon, setDocumentsRoiIcon] = useState(null);

  const [structureTitle, setStructureTitle] = useState(null);

  const [structureCdTitle, setStructureCdTitle] = useState(null);
  const [structureCdText, setStructureCdText] = useState(null);

  const [structureCommitteesTitle, setStructureCommitteesTitle] =
    useState(null);
  const [structureCommitteesText, setStructureCommitteesText] = useState(null);

  const [structureDivisionsTitle, setStructureDivisionsTitle] = useState(null);
  const [structureDivisionsText, setStructureDivisionsText] = useState(null);

  const [structureProjectsTitle, setStructureProjectsTitle] = useState(null);
  const [structureProjectsText, setStructureProjectsText] = useState(null);

  const [membersTitle, setMembersTitle] = useState(null);
  const [membersText, setMembersText] = useState(null);
  const [membersFullTitle, setMembersFullTitle] = useState(null);
  const [membersObsTitle, setMembersObsTitle] = useState(null);

  useEffect(() => {
    if (
      resourcesArray === undefined ||
      resourcesArray === null ||
      !Array.isArray(resourcesArray)
    ) {
      setPageTitle(null);
      setDescriptionText(null);
      setFinalImage(null);
      setLogoFasmrSimpleBlue(null);

      setHistoryTitle(null);
      setHistoryText(null);

      setDocumentsCover(null);
      setDocumentsFirstText(null);

      setDocumentsStatutTitle(null);
      setDocumentsStatutText(null);
      setDocumentsStatutIcon(null);

      setDocumentsRoiTitle(null);
      setDocumentsRoiText(null);
      setDocumentsRoiIcon(null);

      setStructureTitle(null);

      setStructureCdTitle(null);
      setStructureCdText(null);

      setStructureCommitteesTitle(null);
      setStructureCommitteesText(null);

      setStructureDivisionsTitle(null);
      setStructureDivisionsText(null);

      setStructureProjectsTitle(null);
      setStructureProjectsText(null);

      setMembersTitle(null);
      setMembersText(null);
      setMembersFullTitle(null);
      setMembersObsTitle(null);
      return;
    }

    resourcesArray.forEach((resource) => {
      if (resource.id === resourceKeys.pageTitle) setPageTitle(resource);
      if (resource.id === resourceKeys.descriptionText)
        setDescriptionText(resource);
      if (resource.id === resourceKeys.finalImage) setFinalImage(resource);
      if (resource.id === resourceKeys.logoFasmrSimpleBlue)
        setLogoFasmrSimpleBlue(resource);

      if (resource.id === resourceKeys.historyTitle) setHistoryTitle(resource);
      if (resource.id === resourceKeys.historyText) setHistoryText(resource);

      if (resource.id === resourceKeys.documentsCover)
        setDocumentsCover(resource);
      if (resource.id === resourceKeys.documentsFirstText)
        setDocumentsFirstText(resource);

      if (resource.id === resourceKeys.documentsStatutTitle)
        setDocumentsStatutTitle(resource);
      if (resource.id === resourceKeys.documentsStatutText)
        setDocumentsStatutText(resource);
      if (resource.id === resourceKeys.documentsStatutIcon)
        setDocumentsStatutIcon(resource);

      if (resource.id === resourceKeys.documentsRoiTitle)
        setDocumentsRoiTitle(resource);
      if (resource.id === resourceKeys.documentsRoiText)
        setDocumentsRoiText(resource);
      if (resource.id === resourceKeys.documentsRoiIcon)
        setDocumentsRoiIcon(resource);

      if (resource.id === resourceKeys.structureTitle)
        setStructureTitle(resource);

      if (resource.id === resourceKeys.structureCdTitle)
        setStructureCdTitle(resource);
      if (resource.id === resourceKeys.structureCdText)
        setStructureCdText(resource);

      if (resource.id === resourceKeys.structureCommitteesTitle)
        setStructureCommitteesTitle(resource);
      if (resource.id === resourceKeys.structureCommitteesText)
        setStructureCommitteesText(resource);

      if (resource.id === resourceKeys.structureDivisionsTitle)
        setStructureDivisionsTitle(resource);
      if (resource.id === resourceKeys.structureDivisionsText)
        setStructureDivisionsText(resource);

      if (resource.id === resourceKeys.structureProjectsTitle)
        setStructureProjectsTitle(resource);
      if (resource.id === resourceKeys.structureProjectsText)
        setStructureProjectsText(resource);

      if (resource.id === resourceKeys.membersTitle) setMembersTitle(resource);
      if (resource.id === resourceKeys.membersText) setMembersText(resource);
      if (resource.id === resourceKeys.membersFullTitle)
        setMembersFullTitle(resource);
      if (resource.id === resourceKeys.membersObsTitle)
        setMembersObsTitle(resource);
    });
  }, [resourcesArray]);
  return (
    <div id="page-despre-noi">
      <h4 className="motto">
        Împreună facem în continuare <span>diferența!</span>
      </h4>
      <SectionDescriere
        title={pageTitle}
        text={descriptionText}
        logo={logoFasmrSimpleBlue}
      />
      <SectionIstoric title={historyTitle} text={historyText} />
      <SectionStatutRoi
        cover={documentsCover}
        firstText={documentsFirstText}
        statut={[
          documentsStatutTitle,
          documentsStatutText,
          documentsStatutIcon,
        ]}
        roi={[documentsRoiTitle, documentsRoiText, documentsRoiIcon]}
      />
      <SectionStructura
        title={structureTitle}
        cd={[structureCdTitle, structureCdText, cdPeople]}
        committees={[
          structureCommitteesTitle,
          structureCommitteesText,
          committees,
        ]}
        divisions={[structureDivisionsTitle, structureDivisionsText, divisions]}
        projects={[structureProjectsTitle, structureProjectsText, projects]}
      />
      <div
        className="container-wrapper"
        style={{
          height: "var(--gap-xl)",
          backgroundColor: "var(--color-light)",
        }}
      />
      <SectionAsociatii
        title={membersTitle}
        text={membersText}
        members={[membersFullTitle, membersFull, membersObsTitle, membersObs]}
      />
      <div
        className="container-wrapper last-image"
        style={{ backgroundColor: "var(--color-gray-light)" }}
      >
        <div className="container">
          <ResourceDisplay resource={finalImage} className="resource" />
        </div>
      </div>
    </div>
  );
}
Component.displayName = "page-about";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-about-error";
