import "./styles.css";

import { getResources } from "../../service/resource/index.ts";
import { useLoaderData, useRouteError } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import ResourceDisplay from "../../components/resource-display/index.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram } from "@fortawesome/fontawesome-free-brands";
import ButtonClipboardCopy from "../../components/button-clipboard-copy/index.tsx";
import ButtonPrimary from "../../components/button-primary/index.tsx";
import { sendEmail } from "../../service/contact/index.ts";
import { SyncLoader } from "react-spinners";
import STYLES_CONSTANTS from "../../styles/index.js";
import background from "../../images/lines-background-element.svg";
import GenericErrorDisplay from "../generic-error";

export const path = "/contact/";

const resourceKeys = {
  element: "contact-banner-element",
  pageTitle: "contact-title",
  pageSubtitle: "contact-subtitle",
  address: "address",
  facebookUrl: "facebook-url",
  instagramUrl: "instagram-url",
  email: "contact-email",
  formTitle: "contact-form-title",
  formSubtitle: "contact-form-subtitle",
  successMessage: "contact-form-success",
  errorMessage: "contact-form-error",
};

export async function loader() {
  return await getResources(Object.values(resourceKeys));
}

export function Component() {
  const resourcesArray = useLoaderData();
  const [element, setElement] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);
  const [pageSubtitle, setPageSubtitle] = useState(null);
  const [address, setAddress] = useState(null);
  const [facebookUrl, setFacebookUrl] = useState(null);
  const [instagramUrl, setInstagramUrl] = useState(null);
  const [email, setEmail] = useState(null);
  const [formTitle, setFormTitle] = useState(null);
  const [formSubtitle, setFormSubtitle] = useState(null);
  useEffect(() => {
    if (
      resourcesArray === undefined ||
      resourcesArray === null ||
      !Array.isArray(resourcesArray)
    ) {
      setElement(null);
      setPageTitle(null);
      setPageSubtitle(null);
      setAddress(null);
      setFacebookUrl(null);
      setInstagramUrl(null);
      setEmail(null);
      setFormTitle(null);
      setFormSubtitle(null);
      return;
    }

    resourcesArray.forEach((resource) => {
      if (resource.id === resourceKeys.element) setElement(resource);
      if (resource.id === resourceKeys.pageTitle) setPageTitle(resource);
      if (resource.id === resourceKeys.pageSubtitle) setPageSubtitle(resource);
      if (resource.id === resourceKeys.address) setAddress(resource);
      if (resource.id === resourceKeys.facebookUrl) setFacebookUrl(resource);
      if (resource.id === resourceKeys.facebookUrl) setInstagramUrl(resource);
      if (resource.id === resourceKeys.email) setEmail(resource);
      if (resource.id === resourceKeys.formTitle) setFormTitle(resource);
      if (resource.id === resourceKeys.formSubtitle) setFormSubtitle(resource);
      if (resource.id === resourceKeys.successMessage);
    });
  }, [resourcesArray]);

  const [color, setColor] = useState(null);

  const formRef = useRef();
  const [resultMessage, setResultMessage] = useState(undefined);

  async function handleFormSubmit(e) {
    e.preventDefault();

    const data = new FormData(formRef.current);
    setResultMessage(null);
    const [success, message] = await sendEmail(data);
    setColor(success ? "green" : "red");
    setResultMessage(message);
  }

  return (
    <div id="page-contact">
      <div className="background">
        <img src={background} alt="" />
      </div>
      <div className="header">
        <div className="element-grafic">
          <ResourceDisplay resource={element} />
        </div>
        <div className="text-container">
          <ResourceDisplay
            resource={pageTitle}
            className="title"
            wrapper="h1"
          />
          <ResourceDisplay resource={pageSubtitle} className="text" />
          <address>
            <FontAwesomeIcon icon={faLocationDot} size="xl" />
            <ResourceDisplay resource={address} />
          </address>
          <div className="icons">
            <a href={facebookUrl?.value}>
              <FontAwesomeIcon icon={faFacebookF} className="icon" />
            </a>
            <a href={instagramUrl?.value}>
              <FontAwesomeIcon icon={faInstagram} className="icon" />
            </a>
            <ButtonClipboardCopy text={email?.value} title="E-mail">
              <FontAwesomeIcon icon={faEnvelope} className="icon" />
            </ButtonClipboardCopy>
          </div>
        </div>
      </div>
      <div className="body">
        <ResourceDisplay resource={formTitle} className="title" wrapper="h2" />
        <ResourceDisplay
          resource={formSubtitle}
          className="text"
          wrapper="h4"
        />
        <div className="content-container">
          <form ref={formRef}>
            <label for="name">Nume</label>
            <input type="text" name="name" id="contact-form-name" />
            <label for="email">E-mail</label>
            <input type="text" name="email" id="contact-form-email" />
            <label for="title">Titlu</label>
            <input type="text" name="title" id="contact-form-title" />
            <label for="message">Mesaj</label>
            <textarea name="message" id="contact-form-message" rows="10" />
            <div />
            <ButtonPrimary onClick={handleFormSubmit} className="submit">
              Trimite
            </ButtonPrimary>
            <div />
            <div style={{ color }}>
              {resultMessage === null ? (
                <SyncLoader color={STYLES_CONSTANTS["--color-fasmr-blue"]} />
              ) : (
                resultMessage
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
Component.displayName = "page-contact";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-contact-error";
