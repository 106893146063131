import axios from "axios";
import { SERVER_HOST } from "../config.ts";

const uri: string = "/person.php";
const endpoint: string = SERVER_HOST + uri;

export async function getGroup(group: string): Promise<any> {
  const url: string = endpoint;

  const result = (
    await axios.get(url, {
      params: { group: JSON.stringify(group) },
    })
  ).data;

  return result;
}
