import React from "react";
import "./styles.css";
import NeuronBackground from "../../../../images/menu-bacground-neuron-dark-gray.svg";
import TreeNavigationMenuNode, { TreeNavigationMenuLinkNode } from "./node.tsx";
import {
  asociatiiMembre,
  descriereValues,
  structuraFederatiei,
} from "../section-despre-noi/index.jsx";

import { path as noutatiPath } from "../../../../pages/pages-noutati/index";
import { path as oportunitatiPath } from "../../../../pages/page-oportunitati/index.jsx";
import { path as resursePath } from "../../../../pages/page-resurse/index.jsx";
import { path as contactPath } from "../../../../pages/page-contact/index.jsx";
import { path as sustinePath } from "../../../../pages/page-sustine/index.jsx";

function TreeNavigationMenu({ news }) {
  return (
    <div className="tree-navigation-menu">
      <div className="background-graphics">
        <img src={NeuronBackground} alt="" />
      </div>
      <div className="content">
        <TreeNavigationMenuNode
          title="Despre noi"
          className="navigation-menu-section despre-noi"
        >
          <TreeNavigationMenuNode title="Descrierea federației">
            {descriereValues.map((element) => (
              <TreeNavigationMenuLinkNode href={element.href}>
                {element.name}
              </TreeNavigationMenuLinkNode>
            ))}
          </TreeNavigationMenuNode>
          <TreeNavigationMenuNode title="Asociații membre">
            <div className="left table-container">{asociatiiMembre}</div>
          </TreeNavigationMenuNode>
          <TreeNavigationMenuNode title="Structura federației">
            <div className="right table-container">{structuraFederatiei}</div>
          </TreeNavigationMenuNode>
        </TreeNavigationMenuNode>
        <TreeNavigationMenuLinkNode href={oportunitatiPath}>
          Oportunități
        </TreeNavigationMenuLinkNode>
        <TreeNavigationMenuNode title="Noutăți">
          <TreeNavigationMenuNode title="Cele mai recente">
            {news.map((element) => (
              <TreeNavigationMenuLinkNode
                href={noutatiPath + "/" + element.name}
              >
                {element.title}
              </TreeNavigationMenuLinkNode>
            ))}
          </TreeNavigationMenuNode>
          <TreeNavigationMenuLinkNode href={noutatiPath}>
            Vezi toate noutățile
          </TreeNavigationMenuLinkNode>
        </TreeNavigationMenuNode>
        <TreeNavigationMenuLinkNode href={resursePath}>
          Resurse
        </TreeNavigationMenuLinkNode>
        <div className="stretcher" />
        <TreeNavigationMenuLinkNode href={contactPath}>
          Contactează-ne
        </TreeNavigationMenuLinkNode>
        <TreeNavigationMenuLinkNode href={sustinePath}>
          Susține-ne
        </TreeNavigationMenuLinkNode>
        <TreeNavigationMenuLinkNode href={"/"}>
          Acasă
        </TreeNavigationMenuLinkNode>
      </div>
    </div>
  );
}

export default TreeNavigationMenu;
