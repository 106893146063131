import "./styles.css";

function SectionResurse({ active }) {
  return (
    <>
      {active && <div className="navigation-menu-section resurse">resurse</div>}
    </>
  );
}

export default SectionResurse;
