import {
  Outlet,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
} from "react-router-dom";
import NavigationBar from "../components/navigation-bar";
import PageFooter, {
  DEFAULT_FOOTER_LINKTREE_COUNT,
} from "../components/page-footer/index.tsx";
import ScrollToHash from "../utils/scroll-to-hash";
import { getResources } from "../service/resource/index.ts";
import { useEffect, useState } from "react";
import Resource from "../dtos/resource/index.ts";
import ResourceType from "../dtos/resource/type.ts";
import { getLinktreeList } from "../service/linktree/index.ts";
import GenericErrorDisplay from "./generic-error/index.jsx";
import useScrollPosition from "../hooks/useScrollPosition.ts";
import { getNewsList } from "../service/news/get.ts";

const resourceKeys = {
  footerTitle: "footer-title",
  footerSubtitle: "address",
  facebookUrl: "facebook-url",
  instagramUrl: "instagram-url",
};

export async function loader({ request, params }) {
  const resources = getResources(Object.values(resourceKeys));
  const links = getLinktreeList(DEFAULT_FOOTER_LINKTREE_COUNT);
  const news = getNewsList(1, 4);
  return [await resources, await links, await news];
}

/**
 * This react component that specifies the general structure and layout of every page.
 * It uses the component 'Outlet' from react-router for the specific content of each page.
 *
 * @returns a react component to be used for the top-level 'Route' component of the router tree.
 */
function PageLayout() {
  const [resourcesArray, linktreeList, newsList] = useLoaderData();
  const [footerTitle, setFooterTitle] = useState(
    new Resource(resourceKeys.footerTitle, ResourceType.text, "")
  );
  const [footerSubtitle, setFooterSubtitle] = useState(
    new Resource(resourceKeys.footerSubtitle, ResourceType.text, "")
  );
  const [instagramUrl, setInstagramUrl] = useState(
    new Resource(resourceKeys.footerSubtitle, ResourceType.text, "")
  );
  const [facebookUrl, setFacebookUrl] = useState(
    new Resource(resourceKeys.footerSubtitle, ResourceType.text, "")
  );
  useEffect(() => {
    if (
      resourcesArray === undefined ||
      resourcesArray === null ||
      !Array.isArray(resourcesArray)
    ) {
      setFooterTitle(
        new Resource(resourceKeys.footerTitle, ResourceType.text, "")
      );
      setFooterTitle(
        new Resource(resourceKeys.footerSubtitle, ResourceType.text, "")
      );
      return;
    }

    resourcesArray.forEach((resource) => {
      if (resource.id === resourceKeys.footerTitle) setFooterTitle(resource);
      if (resource.id === resourceKeys.footerSubtitle)
        setFooterSubtitle(resource);
      if (resource.id === resourceKeys.facebookUrl) setFacebookUrl(resource);
      if (resource.id === resourceKeys.instagramUrl) setInstagramUrl(resource);
    });
  }, [resourcesArray]);

  const scrollPosition = useScrollPosition();
  const location = useLocation();
  const navStyle = scrollPosition === 0 &&
    location.pathname === "/" && {
      height: 0,
      minHeight: 0,
      margin: 0,
      padding: 0,
      overflow: "hidden",
    };

  return (
    <>
      <NavigationBar style={navStyle} news={newsList} />
      <div
        style={{
          minHeight: "var(--nav-height  )",
          ...navStyle,
        }}
      />
      <Outlet />
      <PageFooter
        title={footerTitle}
        subtitle={footerSubtitle}
        facebookUrl={facebookUrl}
        secondColumn={linktreeList}
        instagramUrl={instagramUrl}
      />
      <ScrollRestoration />
      <ScrollToHash />
    </>
  );
}
export default PageLayout;

export function MainErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
