import ResourceDisplay from "../../../../components/resource-display";
import "./styles.css";

export const id = "istoric";
function SectionIstoric({ title, text }) {
  return (
    <div id={id} className="container-wrapper">
      <div className="container content-container">
        <div className="card">
          <ResourceDisplay resource={title} wrapper="h2" />
          <ResourceDisplay resource={text} />
        </div>
      </div>
    </div>
  );
}

export default SectionIstoric;
