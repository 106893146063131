import "./styles.css";
import ResourceDisplay from "../resource-display/index.jsx";

export default function Perfuzie({ text, sange, number }) {
  return (
    <div className="punga">
      <div className="all-container">
        <div className="upper-container">
          <h3 className="upper-container-number">{number}</h3>
        </div>
        <div className="main-container">
          <div className="main-container-text">
            <ResourceDisplay resource={text} />
          </div>
          <div className="main-container-sange">
            <ResourceDisplay resource={sange} />
            <ResourceDisplay
              resource={sange}
              style={{ transform: "rotateZ(180deg)", marginTop: "-10px" }}
            />
          </div>
        </div>
        <div className="bottom-container">
          <div className="bottom-element-container">
            <ResourceDisplay className="bottom-image" resource={sange} />
          </div>
          <div className="bottom-element-container">
            <ResourceDisplay className="bottom-image" resource={sange} />
          </div>
          <div className="bottom-element-container">
            <ResourceDisplay className="bottom-image" resource={sange} />
          </div>
        </div>
      </div>
    </div>
  );
}
