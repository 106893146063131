import NewsSummary from "../../dtos/news/summary.ts";
import Resource from "../../dtos/resource/index.ts";
import ResourceType from "../../dtos/resource/type.ts";
import ServiceError from "../error.ts";
import NewsDetail from "../../dtos/news/detail.ts";


export function toNewsSummary(news: any): NewsSummary {
  if (news.id === undefined || news.id === null || String(news.id) === "")
    throw new ServiceError("Invalid news", "Empty id.", news);

  if (news.name === undefined || news.name === null)
    throw new ServiceError(
      "Invalid news",
      `Empty name for news ${news.id}.`,
      news
    );
  if (news.title === undefined || news.title === null)
    throw new ServiceError(
      "Invalid news",
      `Empty title for news ${news.id}.`,
      news
    );
  if (news.content === undefined || news.content === null)
    throw new ServiceError(
      "Invalid news",
      `Empty content for news ${news.id}.`,
      news
    );
  if (news.date === undefined || news.date === null)
    throw new ServiceError(
      "Invalid news",
      `Empty date for news ${news.id}.`,
      news
    );
  if (news.front_img === undefined || news.front_img === null)
    throw new ServiceError(
      "Invalid news",
      `Empty front_img for news ${news.id}.`,
      news
    );

  const id = parseInt(news.id);
  const name = String(news.name);
  const title = String(news.title);
  const content = String(news.content);
  const date = new Date(news.date);
  const front_img = new Resource(
    "news-image",
    ResourceType.image,
    String(news.front_img)
  );
  const hidden = Boolean(news.hidden ? news.hidden : false);

  if (isNaN(id))
    throw new ServiceError(
      "Invalid news",
      `News id ${news.id} is not a number.`,
      news
    );
  if (isNaN(date.getDay()))
    throw new ServiceError(
      "Invalid news",
      `News date ${news.date} is not a date.`,
      news
    );

  return new NewsSummary(id, name, title, content, date, front_img, hidden);
}

export function toNewsDetail( item: any): NewsDetail{
  const convert: NewsSummary=toNewsSummary(item);
  
  if (item.back_img === undefined ||  item.back_img === null || item.back_img==="")
    throw new ServiceError(
      "Invalid item",
      `Empty back_img for item ${convert.id}.`,
      item
    );
  if (item.facebook === undefined || item.facebook === null )
    throw new ServiceError(
      "Invalid item",
      `Empty facebook for item ${convert.id}.`,
      item
    );
  if (item.instagram === undefined || item.instagram === null )
    throw new ServiceError(
      "Invalid item",
      `Empty instagram for item ${convert.id}.`,
      item
    );
  if (item.hashtags === undefined || item.hashtags === null || !Array.isArray(item.hashtags))
    throw new ServiceError(
      "Invalid item",
      `Empty hashtags for item ${convert.id}.`,
      item
    );
  if (item.related === undefined || item.related === null || !Array.isArray(item.related) )
    throw new ServiceError(
      "Invalid item",
      `Empty related for item ${convert.id}.`,
      item
    );
  
  const facebook= String (item.facebook);
  const instagram= String (item.instagram);
  const hashtags= item.hashtags.map((x)=>{
    if(x==="")
      throw new ServiceError(
        "Invalid hashtag of news",
        `Empty hashtag for item ${convert.id}.`,
        x
    );
    return String(x);
  })
  const related= item.related.map((elem)=>toNewsSummary(elem));
  const back_img = new Resource(
    "items-image",
    ResourceType.image,
    String(item.back_img)
  );
  return new NewsDetail (convert.id,convert.name,convert.title,convert.content,convert.date,
    convert.front_img, convert.hidden, back_img, facebook, instagram, hashtags, related
  );

  }
