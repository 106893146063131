import "./styles.css";

function XOTable({
  children,
  borderWidth = "1px",
  borderColor = "var(--color-dark)",
  space = "var(--gap-medium)",
  style,
  className,
}) {
  return (
    <table
      className={"xo-table " + className}
      style={{
        ...style,
        borderSpacing: borderWidth,
        border: "none",
        zIndex: 2,
        "--border-color": borderColor,
        "--space": space,
        "--border-width": borderWidth,
      }}
    >
      {children}
    </table>
  );
}

export default XOTable;
