import Resource from "../../dtos/resource/index.ts";
import { get } from "../../api/resource/get.ts";
import ServiceError from "../error.ts";
import ResourceType from "../../dtos/resource/type.ts";

export function toResource(resource: any): Resource {
  if (resource.id === undefined || resource.id === null)
    throw new ServiceError("Invalid resource", "Empty id.", resource);

  if (resource.type === undefined || resource.type === null)
    throw new ServiceError(
      "Invalid resource",
      `Empty type for resource ${resource.id}.`,
      resource
    );
  if (resource.value === undefined || resource.value === null)
    throw new ServiceError(
      "Invalid resource",
      `Empty value for resource ${resource.id}.`,
      resource
    );

  const type = ResourceType[String(resource.type)];
  if (type === undefined || type === null)
    throw new ServiceError(
      "Invalid resource",
      `Type ${resource.type} not accepted for resource ${resource.id}.`
    );

  return new Resource(String(resource.id), type, String(resource.value));
}

export async function getResources(
  ids: string[] | string
): Promise<Resource[] | Resource> {
  const rawData: any = await get(ids);
  if (!Array.isArray(rawData)) return toResource(rawData);

  const arrayData: any[] = rawData;

  return arrayData.map((resource) => toResource(resource));
}
