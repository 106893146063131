import { useEffect, useState } from "react";
import LeftListItem from "./left-list-item";
import "./styles.css";
import NeuronBackground from "../../../images/menu-bacground-neuron-dark-gray.svg";
import SectionDespreNoi from "./section-despre-noi";
import SectionNoutati from "./section-noutati";
import SectionOportunitati from "./section-oportunitati";
import SectionResurse from "./section-resurse";
import useWindowDimensions from "../../../hooks/useWindowDimensions.ts";
import TreeNavigationMenu from "./tree/index.tsx";

import { Link } from "react-router-dom";
import { path as contactPath } from "../../../pages/page-contact/index.jsx";

function NavigationMenu({ active, news }) {
  const SECTION = {
    despreNoi: 1,
    oportunitati: 2,
    noutati: 3,
    resurse: 4,
    contact: 5,
    home: 6,
  };
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    if (!active) setSelectedSection(null);
  }, [active]);

  const dimensions = useWindowDimensions();

  return (
    <>
      {active &&
        (dimensions.width > 850 ? (
          <div className="navigation-menu">
            {/* <div className="overlay-graphics"></div> */}
            <div className="container">
              <div className="left-list">
                <div className="items-container">
                  <LeftListItem
                    href="/"
                    active={selectedSection === SECTION.despreNoi}
                    setActive={(value) => {
                      if (value) setSelectedSection(SECTION.despreNoi);
                    }}
                  >
                    Despre noi
                  </LeftListItem>
                  <LeftListItem
                    href="/"
                    active={selectedSection === SECTION.oportunitati}
                    setActive={(value) => {
                      if (value) setSelectedSection(SECTION.oportunitati);
                    }}
                  >
                    Oportunități
                  </LeftListItem>
                  <LeftListItem
                    href="/"
                    active={selectedSection === SECTION.noutati}
                    setActive={(value) => {
                      if (value) setSelectedSection(SECTION.noutati);
                    }}
                  >
                    Noutăți
                  </LeftListItem>
                  <LeftListItem
                    href="/"
                    active={selectedSection === SECTION.resurse}
                    setActive={(value) => {
                      if (value) setSelectedSection(SECTION.resurse);
                    }}
                  >
                    Resurse
                  </LeftListItem>
                  <div className="bottom-item">
                    <LeftListItem
                      href="/"
                      active={selectedSection === SECTION.contact}
                      setActive={(value) => {
                        if (value) setSelectedSection(SECTION.contact);
                      }}
                    >
                      <Link to={contactPath} className="element">
                        Contactează-ne
                      </Link>
                    </LeftListItem>
                    <LeftListItem
                      href="/"
                      active={selectedSection === SECTION.home}
                      setActive={(value) => {
                        if (value) setSelectedSection(SECTION.home);
                      }}
                    >
                      <Link className="element">Acasă</Link>
                    </LeftListItem>
                  </div>
                </div>
                <div className="stretcher" />
              </div>
              <div className="right-side">
                <div className="background-graphics">
                  <img src={NeuronBackground} alt="" className="w" />
                </div>
                <SectionDespreNoi
                  active={selectedSection === SECTION.despreNoi}
                />
                <SectionOportunitati
                  active={selectedSection === SECTION.oportunitati}
                />
                <SectionNoutati
                  active={selectedSection === SECTION.noutati}
                  news={news}
                />
                <SectionResurse active={selectedSection === SECTION.resurse} />
              </div>
            </div>
          </div>
        ) : (
          <TreeNavigationMenu news={news} />
        ))}
    </>
  );
}

export default NavigationMenu;
