import Resource from "../resource";

class NewsSummary {
  id: number;
  name: string;
  title: string;
  content: string;
  date: Date;
  front_img: Resource;
  hidden: boolean;

  constructor(
    id: number,
    name: string,
    title: string,
    content: string,
    date: Date,
    front_img: Resource,
    hidden: boolean = false
  ) {
    this.id = id;
    this.name = name;
    this.title = title;
    this.content = content;
    this.date = date;
    this.front_img = front_img;
    this.hidden = hidden;
  }
}

export default NewsSummary;
