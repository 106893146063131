import { Outlet, Route } from "react-router-dom";
import * as NoutateDetaliu from "./page-noutate-detaliu";
import * as NoutatiIndex from "./page-noutati-index/index.tsx";

export const path = "/news/";

export function Component() {
  return <Outlet />;
}
Component.displayName = "pages-noutati";

export const children = [
  <Route index {...NoutatiIndex} />,
  <Route {...NoutateDetaliu} />,
];
