const STYLES_CONSTANTS = {
  "--color-fasmr-blue": "#3090d8",
  "--color-dark": "#474747",
  "--color-light": "#f4f4f4",
  "--color-gray-dark": "#d0d0d0",
  "--color-gray": "#e0e0e0",
  "--color-gray-light": "#ececec",

  "--gap-small": "12px",
  "--gap-medium": "26px",
  "--gap-big": "40px",

  "--border-radius-small": "12px",

  "--box-shadow-small": "0 3px 8px 0px rgba(0, 0, 0, 0.4)",
  "--drop-shadow-small": "0 4px 4px rgba(0, 0, 0, 0.4)",

  "--font-primary": "Montserrat",
  "font-family": "var(--font-primary)",

  transition: "all 0.2s ease",
  "z-index": "1",

  "--nav-height": "45px",
  "--content-height": "calc(100dvh - var(--nav-height) - 2 * var(--gap-small))",
};

export default STYLES_CONSTANTS;
