import "./styles.css";
import Separator from "../../../separator";
import XOTable from "../../../xo-table";

import { path as DespreNoiPath } from "../../../../pages/pages-despre-noi";
import { id as descriereId } from "../../../../pages/pages-despre-noi/page-despre-noi-index/section-descriere";
import { id as istoricId } from "../../../../pages/pages-despre-noi/page-despre-noi-index/section-istoric";
import { id as structuraId } from "../../../../pages/pages-despre-noi/page-despre-noi-index/section-structura";
import {
  statutLocation,
  roiLocation,
} from "../../../../pages/pages-despre-noi/page-despre-noi-index/section-statut-roi";
import { path as GaleriePath } from "../../../../pages/pages-despre-noi/page-galerie";
import { path as AsociatiiPath } from "../../../../pages/pages-despre-noi/page-asociatii";
import { path as CDPath } from "../../../../pages/pages-despre-noi/page-cd";
import { path as ComitetePath } from "../../../../pages/pages-despre-noi/pages-comitete";
import { path as DiviziiPath } from "../../../../pages/pages-despre-noi/page-divizii";
import { path as ScomePath } from "../../../../pages/pages-despre-noi/pages-comitete/page-scome";
import { path as ScophPath } from "../../../../pages/pages-despre-noi/pages-comitete/page-scoph";
import { path as ScorpPath } from "../../../../pages/pages-despre-noi/pages-comitete/page-scorp";
import { path as ScoraPath } from "../../../../pages/pages-despre-noi/pages-comitete/page-scora";
import { path as ExchangesPath } from "../../../../pages/pages-despre-noi/pages-comitete/page-exchanges";
import { id as IGId } from "../../../../pages/pages-despre-noi/page-divizii/section-ig";
import { id as CBId } from "../../../../pages/pages-despre-noi/page-divizii/section-cb";
import { id as PRId } from "../../../../pages/pages-despre-noi/page-divizii/section-pr";
import { id as FRId } from "../../../../pages/pages-despre-noi/page-divizii/section-fr";
import { Link } from "react-router-dom";

export const descriereValues = [
  {
    name: "Descrierea federatiei",
    href: DespreNoiPath + "#" + descriereId,
  },
  {
    name: "Istoric",
    href: DespreNoiPath + "#" + istoricId,
  },
  {
    name: "Statut",
    href: statutLocation,
  },
  {
    name: "ROI",
    href: roiLocation,
  },
  {
    name: "Galerie",
    href: DespreNoiPath + "/" + GaleriePath,
  },
];

export const asociatiiMembre = (
  <div className="asociatii-table-container">
    <XOTable
      className="asociatii-table"
      space="10%"
      borderColor="var(--color-gray-dark)"
    >
      <tr>
        <td>ASMO</td>
        <td>ASMTMC</td>
        <td>ASSM</td>
      </tr>
      <tr>
        <td>LSTGM</td>
        <td>OSM</td>
        <td>SSMB</td>
      </tr>
      <tr>
        <td>SSMC</td>
        <td>SSMG</td>
        <td>SSMHS</td>
      </tr>
      <tr>
        <td>SSMI</td>
        <td>SSMT</td>
        <td>AUMAM</td>
      </tr>
    </XOTable>
  </div>
);

export const structuraFederatiei = (
  <div className="right-contents-container">
    <div className="right-columns-container">
      <div className="right-columns">
        <div className="column-container">
          <Link
            to={DespreNoiPath + "/" + ComitetePath}
            className="comitet header"
          >
            Comitete
          </Link>
          <div className="comitete-column">
            <Link
              to={DespreNoiPath + "/" + ComitetePath + "/" + ScomePath}
              className="comitet"
            >
              SCOME
            </Link>
            <Link
              to={DespreNoiPath + "/" + ComitetePath + "/" + ExchangesPath}
              className="comitet"
            >
              SCOPE-SCORE
            </Link>
            <Link
              to={DespreNoiPath + "/" + ComitetePath + "/" + ScophPath}
              className="comitet"
            >
              SCOPH
            </Link>
            <Link
              to={DespreNoiPath + "/" + ComitetePath + "/" + ScoraPath}
              className="comitet"
            >
              SCORA
            </Link>
            <Link
              to={DespreNoiPath + "/" + ComitetePath + "/" + ScorpPath}
              className="comitet"
            >
              SCORP
            </Link>
          </div>
        </div>
        <Separator vertical color="var(--color-gray-dark)" space="0" />
        <div className="column-container">
          <Link
            to={DespreNoiPath + "/" + DiviziiPath}
            className="divizie header"
          >
            Divizii
          </Link>
          <div className="divizii-column">
            <Link
              to={DespreNoiPath + "/" + DiviziiPath + "#" + FRId}
              className="divizie"
            >
              Fundraising
            </Link>
            <Link
              to={DespreNoiPath + "/" + DiviziiPath + "#" + IGId}
              className="divizie"
            >
              It&Grafică
            </Link>
            <Link
              to={DespreNoiPath + "/" + DiviziiPath + "#" + PRId}
              className="divizie"
            >
              Public Relations
            </Link>
            <Link
              to={DespreNoiPath + "/" + DiviziiPath + "#" + CBId}
              className="divizie"
            >
              Capacity building
            </Link>
          </div>
        </div>
      </div>
      <div className="cd-separator-container">
        <Separator color="var(--color-gray-dark)" space="0" />
      </div>
      <Link to={DespreNoiPath + "/" + CDPath} className="cd">
        Consiliul director
      </Link>
    </div>
  </div>
);

function SectionDespreNoi({ active }) {
  return (
    <div
      className={
        "navigation-menu-section despre-noi" + (active ? " active" : "")
      }
    >
      <div className="tab-bar">
        {descriereValues.map((value, index) => (
          <>
            <Link to={value.href} className="tab-item">
              {value.name}
            </Link>
            {index !== descriereValues.length - 1 && (
              <Separator vertical space="0" color="var(--color-gray-dark)" />
            )}
          </>
        ))}
      </div>
      <div className="tables-container">
        <div className="left table-container">
          <Link to={DespreNoiPath + "/" + AsociatiiPath} className="title">
            Asociații membre
          </Link>
          <Separator color="var(--color-gray-dark)" />
          {asociatiiMembre}
        </div>
        <Separator
          vertical
          color="var(--color-gray-dark)"
          space="0"
          style={{ height: "calc(100% - var(--section-gap-medium))" }}
          className="conditional-separator"
        />
        <div className="right table-container">
          <Link to={DespreNoiPath + "#" + structuraId} className="title">
            Structura federației
          </Link>
          <Separator
            color="var(--color-gray-dark)"
            space="var(--section-gap-big)"
          />
          {structuraFederatiei}
        </div>
      </div>
    </div>
  );
}

export default SectionDespreNoi;
