import "./styles.css";
import React from "react";

type propsType = {
  onClick: (e: any) => void;
  children: React.ReactNode;
  className: string;
};

function ButtonPrimary({
  onClick,
  className = "",
  children,
  ...props
}: propsType) {
  return (
    <button
      onClick={onClick}
      {...props}
      className={
        "fill-button " +
        (className === "" ? "button-primary" : "button-primary " + className)
      }
    >
      {children}
    </button>
  );
}

export default ButtonPrimary;
