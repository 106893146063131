import "./styles.css";
import React from "react";
import NewsSummary from "../../dtos/news/summary";
import ResourceDisplay from "../resource-display";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { path as NoutatiPath } from "../../pages/pages-noutati/index.jsx";

function NewsSummaryCard({ newsSummary }: { newsSummary: NewsSummary }) {
  return (
    <Link to={NoutatiPath + newsSummary.name} className="news-summary-card">
      <ResourceDisplay resource={newsSummary.front_img} className="image" />
      <div className="info-container">
        <h3 className="title">{newsSummary.title}</h3>
        <div className="content">{newsSummary.content}</div>
        <div className="last-row label">
          <div className="date">
            {newsSummary.date.toLocaleDateString("ro-RO", {
              dateStyle: "long",
            })}
          </div>
          <FontAwesomeIcon
            icon={faChevronRight}
            size="2xl"
            className="chevron"
          />
        </div>
      </div>
    </Link>
  );
}

export default NewsSummaryCard;
