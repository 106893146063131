import { useEffect, useState } from "react";
import {
  redirect,
  useLoaderData,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import { getResources } from "../../service/resource/index.ts";

import "./styles.css";
import GenericErrorDisplay from "../generic-error/index.jsx";
import ResourceDisplay from "../../components/resource-display";

const paramName = "ref";
export const path = "/redirect/";

export function externalRedirect(ref) {
  return redirect(externalRedirectUrl(ref));
}

export function externalRedirectUrl(ref) {
  const searchParams = new URLSearchParams();
  searchParams.set(paramName, ref);
  return `${path}?${searchParams.toString()}`;
}

const resourceKeys = {
  title: "redirect-title",
  image: "redirect-image",
};

export async function loader({ request }) {
  const resourcesPromise = getResources(Object.values(resourceKeys));

  const url = new URL(request.url);
  const ref = url.searchParams.get(paramName);
  if (ref === undefined || ref === null)
    throw new TypeError("Invalid reference for redirect.");
  return [ref, await resourcesPromise];
}

export function Component() {
  const [ref, resourcesArray] = useLoaderData();
  const navigate = useNavigate();
  useEffect(() => window.location.replace(ref), [ref, navigate]);

  const [title, setTitle] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (
      resourcesArray === undefined ||
      resourcesArray === null ||
      !Array.isArray(resourcesArray)
    ) {
      setTitle(null);
      setImage(null);
      return;
    }

    resourcesArray.forEach((resource) => {
      if (resource.id === resourceKeys.title) setTitle(resource);
      if (resource.id === resourceKeys.image) setImage(resource);
    });
  }, [resourcesArray]);

  return (
    <div id="redirect-page">
      <div className="content-container container">
        <div className="text-container">
          <ResourceDisplay resource={title} wrapper="h2" />
          <div>{ref}</div>
        </div>
        <ResourceDisplay resource={image} className="image" />
      </div>
    </div>
  );
}
Component.displayName = "Component";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "ErrorBoundary";
