import Resource from "../resource/index.ts";

export default class GalleryEntry {
  id: number;
  location: Resource;
  structure?: string;
  caption?: string;

  constructor(
    id: number,
    location: Resource,
    structure: string | undefined = undefined,
    caption: string | undefined = undefined
  ) {
    this.id = id;
    this.location = location;
    this.structure = structure;
    this.caption = caption;
  }
}
