class LinktreeEntry {
  id: string;
  ref: string;
  name: string;
  local: boolean;
  constructor(id: string, ref: string, name: string, local: boolean) {
    this.id = id;
    this.ref = ref;
    this.name = name;
    this.local = local;
  }
}

export default LinktreeEntry;
