import { Link, useLoaderData, useRouteError } from "react-router-dom";
import GenericErrorDisplay from "../../generic-error/index.jsx";
import ResourceDisplay from "../../../components/resource-display/index.jsx";
import "./styles.css";
import { getResources } from "../../../service/resource/index.ts";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingHeart } from "@fortawesome/free-solid-svg-icons";
import { path as Form230Path } from "../page-sustine-230/index.jsx";

const resourceKeys = {
  pageElement: "support-index-element",
  pageTitle: "support-index-title",
  buttonsTitle: "support-index-buttons-title",
  formTitle: "support-index-230-title",
  formButton: "support-index-230-button",
  formElement: "support-index-230-element",
};

export async function loader({ request }) {
  return await getResources(Object.values(resourceKeys));
}

export function Component() {
  const resourcesArray = useLoaderData();

  const [pageElement, setPageElement] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);
  const [buttonsTitle, setButtonsTitle] = useState(null);
  const [formTitle, setFormTitle] = useState(null);
  const [formButton, setFormButton] = useState(null);
  const [formElement, setFormElement] = useState(null);
  useEffect(() => {
    if (
      resourcesArray === undefined ||
      resourcesArray === null ||
      !Array.isArray(resourcesArray)
    ) {
      setPageElement(null);
      setPageTitle(null);
      setButtonsTitle(null);
      setFormTitle(null);
      setFormButton(null);
      setFormElement(null);
      return;
    }

    resourcesArray.forEach((resource) => {
      if (resource.id === resourceKeys.pageElement) setPageElement(resource);
      if (resource.id === resourceKeys.pageTitle) setPageTitle(resource);
      if (resource.id === resourceKeys.buttonsTitle) setButtonsTitle(resource);
      if (resource.id === resourceKeys.formTitle) setFormTitle(resource);
      if (resource.id === resourceKeys.formButton) setFormButton(resource);
      if (resource.id === resourceKeys.formElement) setFormElement(resource);
    });
  }, [resourcesArray]);

  return (
    <div id="sustine">
      <div className="header-bar">
        <FontAwesomeIcon icon={faHandHoldingHeart} className="icon" />
        <ResourceDisplay resource={pageTitle} wrapper="h1" />
      </div>
      <div className="stretcher" />
      <div className="content content-container">
        <div className="information">
          <ResourceDisplay
            resource={buttonsTitle}
            wrapper="h3"
            style={{
              alignItems: "center",
            }}
          />
          <div className="buttons">
            <Link to="/donate-5" className="fill-button">
              5 RON
            </Link>
            <Link to="/donate-10" className="fill-button">
              10 RON
            </Link>
            <Link to="/donate-50" className="fill-button">
              50 RON
            </Link>
            <Link to="/donate-100" className="fill-button">
              100 RON
            </Link>
            <Link to="/donate-200" className="fill-button">
              200 RON
            </Link>
            <Link to="/donate-500" className="fill-button">
              500 RON
            </Link>
          </div>
          <hr style={{ "--color": "var(--color-fasmr-blue)" }} />
          <div className="to230container">
            <ResourceDisplay
              resource={formElement}
              style={{ height: "100px" }}
            />
            <div className="information">
              <ResourceDisplay resource={formTitle} wrapper="h3" />
              <ResourceDisplay
                resource={formButton}
                className="fill-button"
                wrapper={Link}
                to={Form230Path}
              />
            </div>
          </div>
        </div>
        <ResourceDisplay resource={pageElement} className="element" />
      </div>
      <div className="stretcher" />
    </div>
  );
}
Component.displayName = "page-sustine-index";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-sustine-index-error";
