import CardInformationBig from "../../../../components/card-information-big/index.tsx";
import ResourceDisplay from "../../../../components/resource-display";
import useWindowDimensions from "../../../../hooks/useWindowDimensions.ts";
import "./styles.css";

export const statutLocation = "/statut";
export const roiLocation = "/roi";

export const id = "documents";
function SectionStatutRoi({ cover, firstText, statut, roi }) {
  const [statutTitle, statutText, statutIcon] = statut;
  const [roiTitle, roiText, roiIcon] = roi;
  const { width } = useWindowDimensions();
  return (
    <div id={id} className="container-wrapper">
      <div className="container content-container">
        <ResourceDisplay resource={cover} />
        <ResourceDisplay resource={firstText} />
        <div className="content">
          <CardInformationBig
            title={statutTitle}
            text={statutText}
            url={statutLocation}
            icon={<ResourceDisplay resource={statutIcon} />}
          />
          <CardInformationBig
            title={roiTitle}
            text={roiText}
            url={roiLocation}
            icon={<ResourceDisplay resource={roiIcon} />}
            mirror={width > 1000}
          />
        </div>
      </div>
    </div>
  );
}

export default SectionStatutRoi;
