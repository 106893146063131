import { redirect, useRouteError } from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../../generic-error";

import { path as constructionPath } from "../../../page-in-constructie";

export async function loader({ request }) {
  return redirect(constructionPath);
}

export function Component() {
  return <></>;
}
Component.displayName = "page-about-projects";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-about-projects-error";
