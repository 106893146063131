import neuron from "../../images/navbar-neuron.svg";

/**
 * The complex menu button with the neural cell (neuron).
 *
 * @param {*} children Button content
 * @param {function} onClick What to do when the button is clicked.
 * @returns The react component
 */
function MenuButton({ children, onClick }) {
  const styles = {
    container: {
      zIndex: 4,
      position: "relative",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      height: "100%",
      padding: "0",
      border: "none",
      fontSize: "1.1em",
    },
    leftButtonPart: {
      height: "100%",

      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",

      borderRadius: "0 var(--button-height) var(--button-height) 0",
      borderLeft: "none",

      paddingRight: "calc(var(--button-height) * 0.6)",
      paddingLeft: "var(--gap-small)",
      gap: "var(--gap-small)",
      margin: 0,

      fontWeight: "bold",
    },
    neuron: {
      height: "calc(100% + 1.4 * var(--gap-small))",

      position: "relative",
      left: "-1px",
      overflow: "hidden",
    },
    neuronImg: {
      position: "relative",
      left: "-5px",
      transform: "rotate(8deg)",
    },
  };
  return (
    <button
      className="transparent menu-button"
      style={styles.container}
      onClick={onClick}
    >
      <div className="fill-button" style={styles.leftButtonPart}>
        {children}
      </div>
      <div style={styles.neuron} className="neuron">
        <img src={neuron} alt="neuron" className="h" style={styles.neuronImg} />
      </div>
    </button>
  );
}

export default MenuButton;
