import { AxiosError } from "axios";
import { post } from "../../api/form230/index.ts";

export async function sendEmail(data: FormData): Promise<[boolean, string]> {
  try {
    const result = await post(data);
    if (result.status / 100 === 2) return [true, result.data];
    return [false, "Error " + result.status + ": " + result.data];
  } catch (error) {
    console.log(error);
    if (error instanceof AxiosError)
      return [
        false,
        "Error " +
          (error.response !== undefined
            ? error.response.status
            : error.status) +
          ": " +
          (error.response
            ? error.response.data
            : error.code
            ? error.code
            : "AXIOS_ERR"),
      ];
    throw error;
  }
}
