import { Link } from "react-router-dom";
import Structure from "../../dtos/structure/index.ts";
import ResourceDisplay from "../resource-display/index.jsx";
import "./styles.css";
import React from "react";
import { externalRedirectUrl } from "../../pages/page-redirect/index.jsx";

type propsType = {
  structure: Structure;
  treeRoot?: String;
  inverse?: boolean;
  className?: string;
  imageBackgroundColor?: string;
  textBackgroundColor?: string;
  textColor?: string;
  textWrapper?: string;
};

function CardStructure({
  structure,
  treeRoot,
  inverse = false,
  className = "",
  imageBackgroundColor,
  textBackgroundColor,
  textColor,
  textWrapper = "h3",
  ...others
}: propsType) {
  return (
    <Link
      to={
        treeRoot !== undefined
          ? treeRoot + structure.id
          : structure.url !== undefined
          ? externalRedirectUrl(structure.url)
          : `/${structure.id}`
      }
      style={{
        backgroundColor: imageBackgroundColor
          ? imageBackgroundColor
          : inverse
          ? "white"
          : "#" + structure.color.toString(16).padStart(6, "0"),
      }}
      className={"card-structure" + (className === "" ? "" : " " + className)}
      {...others}
    >
      <div className="logo-container">
        <div className="logo">
          <img
            src={
              inverse ? structure.logoSimpleColor : structure.logoSimpleWhite
            }
            alt={structure.id}
          />
        </div>
      </div>
      <div
        className="text-container"
        style={{
          backgroundColor: textBackgroundColor
            ? textBackgroundColor
            : inverse
            ? "#" + structure.color.toString(16).padStart(6, "0")
            : "white",
          color: textColor
            ? textColor
            : inverse
            ? "white"
            : "#" + structure.color.toString(16).padStart(6, "0"),
        }}
      >
        <div className="text">
          <ResourceDisplay
            resource={structure.logoText}
            wrapper={textWrapper}
          />
        </div>
      </div>
    </Link>
  );
}

export default CardStructure;
