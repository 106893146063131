import { redirect, useRouteError } from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../../generic-error/index.jsx";

import { path as exchangesPath } from "../page-exchanges/index.jsx";

export const path = "score/";

export async function loader({ request }) {
  return redirect("../" + exchangesPath);
}

export function Component() {
  return <></>;
}
Component.displayName = "page-about-committees-score";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-about-committees-score-error";
