import { NavLink } from "react-router-dom";

/**
 * Button to be used inside the navigation bar.
 *
 * @param {*} children Button content
 * @param {string} href Destination
 * @returns The react component
 */
function NavigationButton({ children, href }) {
  return (
    <NavLink
      to={href}
      end
      style={({ isActive, isPending }) => {
        return {
          height: "calc(100% + 2 * var(--gap-small))",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",

          overflow: "hidden",
          padding: "0 calc(var(--gap-big) / 2)",

          textDecoration: isPending ? "underline" : "none",
          color: isPending && "var(--color-dark)",
          fontSize: "1.1em",
          fontWeight: "600",
        };
      }}
      className={({ isActive, isPending }) =>
        isPending
          ? "navigation-button active pending"
          : isActive
          ? "navigation-button active"
          : "navigation-button"
      }
    >
      {children}
    </NavLink>
  );
}

export default NavigationButton;
