import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { path } from "../../../../pages/pages-noutati/index";

function SectionNoutati({ active, news }) {
  console.log(news);
  return (
    <div
      className={"navigation-menu-section noutati" + (active ? " active" : "")}
    >
      <Link to={path} className="title">
        Cele mai recente <FontAwesomeIcon size="xs" icon={faChevronRight} />
      </Link>

      <div className="cols">
        {news.map((element) => (
          <Link to={path + element.name} className="col">
            <b className="p1">{element.title}</b>
            <div className="p2">{element.content}</div>
            <FontAwesomeIcon size="xl" icon={faChevronDown} />
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SectionNoutati;
