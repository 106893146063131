import { getStructure } from "../../api/gallery/get.ts";
import ServiceError from "../error.ts";
import GalleryEntry from "../../dtos/gallery/entry.ts";
import Resource from "../../dtos/resource/index.ts";
import ResourceType from "../../dtos/resource/type.ts";

export function toEntry(entry: any): GalleryEntry {
  if (entry.id === undefined || entry.id === null)
    throw new ServiceError("Invalid gallery entry", "Empty id.", entry);

  if (entry.location === undefined || entry.location === null)
    throw new ServiceError(
      "Invalid gallery entry",
      `Empty location for gallery entry ${entry.id}.`,
      entry
    );

  return new GalleryEntry(
    parseInt(entry.id),
    new Resource("gallery entry", ResourceType.image, String(entry.location)),
    entry.structure !== undefined && entry.structure !== null
      ? entry.structure
      : undefined,
    entry.caption !== undefined && entry.caption !== null
      ? entry.caption
      : undefined
  );
}

export async function getStructureGallery(
  structure: string
): Promise<GalleryEntry[]> {
  const rawData: any = await getStructure(structure);
  if (!Array.isArray(rawData))
    throw new ServiceError(
      "Invalid gallery array",
      "Invalid gallery array.",
      rawData
    );

  return rawData.map((entry) => toEntry(entry));
}
