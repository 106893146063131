import Person from "../../dtos/person/index.ts";
import Persongroup from "../../dtos/person/persongroup.ts";
import { getGroup } from "../../api/person/get.ts";
import ServiceError from "../error.ts";
import { IMAGE_HOST } from "../../api/config.ts";

export function toPersongroup(persongroup: any): Persongroup {
  if (persongroup.id === undefined || persongroup.id === null)
    throw new ServiceError("Invalid persongroup", "Empty id.", persongroup);

  if (persongroup.name === undefined || persongroup.name === null)
    throw new ServiceError(
      "Invalid persongroup",
      `Empty name for persongroup ${persongroup.id}.`,
      persongroup
    );

  return new Persongroup(String(persongroup.id), String(persongroup.name));
}

export function toPerson(person: any): Person {
  if (person.id === undefined || person.id === null)
    throw new ServiceError("Invalid person", "Empty id.", person);

  if (person.name === undefined || person.name === null)
    throw new ServiceError(
      "Invalid person",
      `Empty name for person ${person.id}.`,
      person
    );

  if (person.position === undefined || person.position === null)
    throw new ServiceError(
      "Invalid person",
      `Empty position for person ${person.id}.`,
      person
    );

  if (person.email === undefined || person.email === null)
    throw new ServiceError(
      "Invalid person",
      `Empty email for person ${person.id}.`,
      person
    );

  if (person.profile === undefined || person.profile === null)
    throw new ServiceError(
      "Invalid person",
      `Empty profile location for person ${person.id}.`,
      person
    );

  return new Person(
    String(person.id),
    String(person.name),
    String(person.position),
    String(person.email),
    String(person.profile),
    IMAGE_HOST,
    String(person.description),
    person?.persongroups?.map((persongroup) => toPersongroup(persongroup)),
    person?.position_short
  );
}

export async function getPersonGroup(group: string): Promise<Person[]> {
  const rawData: any = await getGroup(group);
  if (!Array.isArray(rawData))
    throw new ServiceError(
      "Invalid person array",
      "Invalid person array.",
      rawData
    );

  return rawData.map((person) => toPerson(person));
}
