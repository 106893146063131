import * as ProiecteIndex from "./page-proiecte-index";
import * as Doneaza from "./page-doneaza";
import * as MiciiSanitari from "./page-micii-sanitari";
import * as Transmed from "./page-transmed";
import { Outlet, Route } from "react-router-dom";

export const path = "projects/";

export function Component() {
  return <Outlet />;
}
Component.displayName = "pages-comitete";

export const children = [
  <Route index {...ProiecteIndex} />,
  <Route {...Doneaza} />,
  <Route {...MiciiSanitari} />,
  <Route {...Transmed} />,
];
