import React, { useState, KeyboardEvent } from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";

type propsType = {
  height?: string;
  className?: string;
  initialValue?: string;
  placeholder?: string;
  onSearch: (query: string) => void;
};

function SearchBox({
  height,
  className,
  initialValue,
  placeholder = "Search...",
  onSearch,
  ...props
}: propsType) {
  const [searchQuery, setSearchQuery] = useState<string>(
    initialValue === undefined ? "" : initialValue!
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    onSearch(searchQuery);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleClear = () => {
    setSearchQuery("");
  };

  return (
    <div
      style={
        height !== undefined && height !== null
          ? {
              height: height,
            }
          : {}
      }
      {...props}
      className={
        "search-box" +
        (className === undefined || className === null ? "" : " " + className)
      }
    >
      <div
        className={
          "input-container" + (searchQuery.length === 0 ? "" : " has-text")
        }
      >
        <input
          type="text"
          value={searchQuery}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          title="search"
          placeholder={placeholder}
        />
        <button
          onClick={handleClear}
          className="clear-button"
          style={searchQuery.length === 0 ? { display: "none" } : {}}
        >
          <FontAwesomeIcon icon={faXmark} size="xl" />
        </button>
      </div>
      <button onClick={handleSearch} className="search-button">
        <FontAwesomeIcon icon={faSearch} size="xl" />
      </button>
    </div>
  );
}

export default SearchBox;
