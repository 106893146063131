import * as ComiteteIndex from "./page-comitete-index";
import * as Scora from "./page-scora";
import * as Scorp from "./page-scorp";
import * as Scoph from "./page-scoph";
import * as Scome from "./page-scome";
import * as Scope from "./page-scope";
import * as Score from "./page-score";
import * as Exchanges from "./page-exchanges";
import { Outlet, Route } from "react-router-dom";

export const path = "comitees/";

export function Component() {
  return <Outlet />;
}
Component.displayName = "pages-comitete";

export const children = [
  <Route index {...ComiteteIndex} />,
  <Route {...Scora} />,
  <Route {...Scorp} />,
  <Route {...Scoph} />,
  <Route {...Scome} />,
  <Route {...Scope} />,
  <Route {...Score} />,
  <Route {...Exchanges} />,
];
