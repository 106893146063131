import {
  Link,
  useLoaderData,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import { getResources } from "../../service/resource/index.ts";
import "./styles.css";
import GenericErrorDisplay from "../generic-error";
import { useEffect, useState } from "react";
import ResourceDisplay from "../../components/resource-display/index.jsx";
import useScrollPosition from "../../hooks/useScrollPosition.ts";
import ButtonPrimarySide from "../../components/button-primary-side/index.tsx";

import { path as aboutPath } from "../pages-despre-noi/index.jsx";
import { path as comitetePath } from "../pages-despre-noi/pages-comitete/index.jsx";
import { path as scomePath } from "../pages-despre-noi/pages-comitete/page-scome/index.jsx";
import { path as exchangesPath } from "../pages-despre-noi/pages-comitete/page-exchanges/index.jsx";
import { path as scorpPath } from "../pages-despre-noi/pages-comitete/page-scorp/index.jsx";
import { path as scophPath } from "../pages-despre-noi/pages-comitete/page-scoph/index.jsx";
import { path as scoraPath } from "../pages-despre-noi/pages-comitete/page-scora/index.jsx";
import { getStructureGroup } from "../../service/structure/index.ts";
import StructureGroup from "../../dtos/structure/group.ts";
import CardStructure from "../../components/card-structure/index.tsx";

import paperCutImage from "../../images/white-paper-cut.svg";
import { getNewsList } from "../../service/news/get.ts";
import NewsSummaryCard from "../../components/news-summary-card/index.tsx";
import { IMAGE_HOST } from "../../api/config.ts";
import { path as newsPath } from "../pages-noutati/index.jsx";
import { path as supportPath } from "../page-sustine/index.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingHeart } from "@fortawesome/free-solid-svg-icons";

import { path as projectsPath } from "../pages-despre-noi/pages-proiecte/index.jsx";
import { path as inConstructionPath } from "../page-in-constructie/index.jsx";

const resourceKeys = {
  logoRight: "logo-fasmr-text-right-white",
  aboutCover: "index-about-cover",
  aboutTitle: "index-about-title",
  aboutText: "index-about-text",
  fieldsTitle: "index-fields-title",

  scomeTitle: "index-fields-scome-title",
  scomeText: "index-fields-scome-text",
  scopeTitle: "index-fields-scope-title",
  scopeText: "index-fields-scope-text",
  scoreTitle: "index-fields-score-title",
  scoreText: "index-fields-score-text",
  scorpTitle: "index-fields-scorp-title",
  scorpText: "index-fields-scorp-text",
  scophTitle: "index-fields-scoph-title",
  scophText: "index-fields-scoph-text",
  scoraTitle: "index-fields-scora-title",
  scoraText: "index-fields-scora-text",

  projectsTitle: "index-projects-title",
  projectsText: "index-projects-text",

  joinTitle: "index-join-title",
  joinText: "index-join-text",
  joinImage: "index-join-image",

  newsTitle: "news-list-title",
  newsCover: "image-paper-white",

  supportTitle: "support-index-title",
};

const NEWS_ON_INDEX_COUNT = 4;

export async function loader() {
  const resources = getResources(Object.values(resourceKeys));
  const projects = getStructureGroup(StructureGroup.project);
  const news = getNewsList(1, NEWS_ON_INDEX_COUNT);

  return [await resources, await projects, await news];
}

export function Component() {
  const [resourcesArray, projects, news] = useLoaderData();
  const [aboutCover, setAboutCover] = useState(null);
  const [aboutTitle, setAboutTitle] = useState(null);
  const [aboutText, setAboutText] = useState(null);
  const [logoRight, setLogoRight] = useState();
  const [fieldsTitle, setFieldsTitle] = useState(null);

  const [scomeTitle, setScomeTitle] = useState(null);
  const [scomeText, setScomeText] = useState(null);
  const [scopeTitle, setScopeTitle] = useState(null);
  const [scopeText, setScopeText] = useState(null);
  const [scoreTitle, setScoreTitle] = useState(null);
  const [scoreText, setScoreText] = useState(null);
  const [scorpTitle, setScorpTitle] = useState(null);
  const [scorpText, setScorpText] = useState(null);
  const [scophTitle, setScophTitle] = useState(null);
  const [scophText, setScophText] = useState(null);
  const [scoraTitle, setScoraTitle] = useState(null);
  const [scoraText, setScoraText] = useState(null);

  const [projectsTitle, setProjectsTitle] = useState(null);
  const [projectsText, setProjectsText] = useState(null);

  const [joinTitle, setJoinTitle] = useState(null);
  const [joinText, setJoinText] = useState(null);
  const [joinImage, setJoinImage] = useState(null);

  const [newsTitle, setNewsTitle] = useState(null);
  const [newsCover, setNewsCover] = useState(null);

  const [supportTitle, setSupportTitle] = useState(null);

  useEffect(() => {
    if (
      resourcesArray === undefined ||
      resourcesArray === null ||
      !Array.isArray(resourcesArray)
    ) {
      setAboutCover(null);
      setLogoRight(null);
      setAboutTitle(null);
      setAboutText(null);
      setFieldsTitle(null);

      setScomeTitle(null);
      setScomeText(null);
      setScopeTitle(null);
      setScopeText(null);
      setScoreTitle(null);
      setScoreText(null);
      setScorpTitle(null);
      setScorpText(null);
      setScophTitle(null);
      setScophText(null);
      setScoraTitle(null);
      setScoraText(null);

      setProjectsTitle(null);
      setProjectsText(null);

      setJoinTitle(null);
      setJoinText(null);
      setJoinImage(null);

      setNewsTitle(null);
      setNewsCover(null);

      setSupportTitle(null);
      return;
    }

    resourcesArray.forEach((resource) => {
      if (resource.id === resourceKeys.logoRight) setLogoRight(resource);
      if (resource.id === resourceKeys.aboutCover) setAboutCover(resource);
      if (resource.id === resourceKeys.aboutTitle) setAboutTitle(resource);
      if (resource.id === resourceKeys.aboutText) setAboutText(resource);
      if (resource.id === resourceKeys.fieldsTitle) setFieldsTitle(resource);

      if (resource.id === resourceKeys.scomeTitle) setScomeTitle(resource);
      if (resource.id === resourceKeys.scomeText) setScomeText(resource);
      if (resource.id === resourceKeys.scopeTitle) setScopeTitle(resource);
      if (resource.id === resourceKeys.scopeText) setScopeText(resource);
      if (resource.id === resourceKeys.scoreTitle) setScoreTitle(resource);
      if (resource.id === resourceKeys.scoreText) setScoreText(resource);
      if (resource.id === resourceKeys.scorpTitle) setScorpTitle(resource);
      if (resource.id === resourceKeys.scorpText) setScorpText(resource);
      if (resource.id === resourceKeys.scophTitle) setScophTitle(resource);
      if (resource.id === resourceKeys.scophText) setScophText(resource);
      if (resource.id === resourceKeys.scoraTitle) setScoraTitle(resource);
      if (resource.id === resourceKeys.scoraText) setScoraText(resource);

      if (resource.id === resourceKeys.projectsTitle)
        setProjectsTitle(resource);
      if (resource.id === resourceKeys.projectsText) setProjectsText(resource);

      if (resource.id === resourceKeys.joinTitle) setJoinTitle(resource);
      if (resource.id === resourceKeys.joinText) setJoinText(resource);
      if (resource.id === resourceKeys.joinImage) setJoinImage(resource);

      if (resource.id === resourceKeys.newsTitle) setNewsTitle(resource);
      if (resource.id === resourceKeys.newsCover) setNewsCover(resource);

      if (resource.id === resourceKeys.supportTitle) setSupportTitle(resource);
    });
  }, [resourcesArray]);

  const scrollPosition = useScrollPosition();

  const navigate = useNavigate();

  return (
    <div id="page-index">
      <header
        className="intro"
        style={{ height: scrollPosition === 0 && "100dvh" }}
      >
        <div style={{ height: "var(--gap-big)" }} />
        <div className="stretcher" />
        <ResourceDisplay resource={logoRight} className="cover-logo" />
        <div className="stretcher" />
        <h4>
          Împreună facem în continuare <b>diferența!</b>
        </h4>
        <div style={{ height: "var(--gap-big)" }} />
      </header>
      <section className="about">
        <div className="content-container container">
          <ResourceDisplay resource={aboutTitle} wrapper="h2" />
          <ResourceDisplay resource={aboutText} className="text-container" />
          <ButtonPrimarySide
            title="Află mai multe"
            style={{ alignSelf: "flex-start" }}
            onClick={() => navigate(aboutPath)}
          />
          <ResourceDisplay resource={aboutCover} className="cover" />
        </div>
      </section>
      <section className="fields">
        <div className="content-container container">
          <ResourceDisplay resource={fieldsTitle} wrapper="h2" />
          <div className="content">
            <>
              {/* SCOME */}
              <ResourceDisplay
                resource={scomeTitle}
                className="card scome"
                wrapper="h3"
              />
              <div className="description">
                <ResourceDisplay resource={scomeText} />
                <Link
                  to={aboutPath + comitetePath + scomePath}
                  className="fill-secondary-button"
                >
                  Află mai multe
                </Link>
              </div>
            </>
            <>
              {/* SCOPE */}
              <ResourceDisplay
                resource={scopeTitle}
                className="card scope"
                wrapper="h3"
              />
              <div className="description">
                <ResourceDisplay resource={scopeText} />
                <Link
                  to={aboutPath + comitetePath + exchangesPath}
                  className="fill-secondary-button"
                >
                  Află mai multe
                </Link>
              </div>
            </>
            <>
              {/* SCORE */}
              <ResourceDisplay
                resource={scoreTitle}
                className="card score"
                wrapper="h3"
              />
              <div className="description">
                <ResourceDisplay resource={scoreText} />
                <Link
                  to={aboutPath + comitetePath + exchangesPath}
                  className="fill-secondary-button"
                >
                  Află mai multe
                </Link>
              </div>
            </>
            <>
              {/* SCOME */}
              <ResourceDisplay
                resource={scorpTitle}
                className="card scorp"
                wrapper="h3"
              />
              <div className="description">
                <ResourceDisplay resource={scorpText} />
                <Link
                  to={aboutPath + comitetePath + scorpPath}
                  className="fill-secondary-button"
                >
                  Află mai multe
                </Link>
              </div>
            </>
            <>
              {/* SCOPH */}
              <ResourceDisplay
                resource={scophTitle}
                className="card scoph"
                wrapper="h3"
              />
              <div className="description">
                <ResourceDisplay resource={scophText} />
                <Link
                  to={aboutPath + comitetePath + scophPath}
                  className="fill-secondary-button"
                >
                  Află mai multe
                </Link>
              </div>
            </>
            <>
              {/* SCORA */}
              <ResourceDisplay
                resource={scoraTitle}
                className="card scora"
                wrapper="h3"
              />
              <div className="description">
                <ResourceDisplay resource={scoraText} />
                <Link
                  to={aboutPath + comitetePath + scoraPath}
                  className="fill-secondary-button"
                >
                  Află mai multe
                </Link>
              </div>
            </>
          </div>
        </div>
      </section>
      <section className="projects">
        <div className="content-container container">
          <ResourceDisplay resource={projectsTitle} wrapper="h2" />
          <ResourceDisplay resource={projectsText} className="text-container" />
          <div className="cards-grid">
            {projects?.map((project) => (
              <CardStructure
                treeRoot={aboutPath + projectsPath}
                structure={project}
                inverse
              />
            ))}
          </div>
        </div>
      </section>
      <section className="join">
        <div className="paper-cut">
          <img src={paperCutImage} alt="" />
        </div>
        <div className="content">
          <div className="text">
            <ResourceDisplay resource={joinTitle} wrapper="h2" />
            <ResourceDisplay resource={joinText} />
            <Link to={inConstructionPath} className="fill-button">
              Află mai multe
            </Link>
          </div>
          <ResourceDisplay resource={joinImage} className="image" />
        </div>
      </section>
      <section
        className="news"
        style={
          newsCover && {
            "--background-image-url":
              "url(" + IMAGE_HOST + newsCover.value + ")",
          }
        }
      >
        <ResourceDisplay resource={newsTitle} wrapper="h2" />
        <Link to={newsPath} className="fill-button">
          Vezi toate noutățile
        </Link>
        <div className="content-container container">
          {news.map((newsSummary) => (
            <NewsSummaryCard newsSummary={newsSummary} key={newsSummary.id} />
          ))}
        </div>
        <Link to={newsPath} className="fill-secondary-button">
          Vezi toate noutățile
        </Link>
      </section>
      <section className="support">
        <Link to={supportPath} className="fill-secondary-button">
          Află mai multe
        </Link>
        <div className="text">
          <FontAwesomeIcon icon={faHandHoldingHeart} />
          <ResourceDisplay resource={supportTitle} wrapper="h2" />
        </div>
      </section>
    </div>
  );
}
Component.displayName = "page-index";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-index-error";
