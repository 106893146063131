import { getCount, getList, getName } from "../../api/news/get.ts";
import NewsSummary from "../../dtos/news/summary.ts";
import ServiceError from "../error.ts";
import { toNewsSummary } from "./index.ts";
import NewsDetail from "../../dtos/news/detail.ts";
import { toNewsDetail } from "./index.ts";



export async function getNewsCount(search?: string): Promise<number> {
  const trimmedSearch = search?.trim();
  const rawObj =
    trimmedSearch !== undefined && trimmedSearch.length > 0
      ? await getCount(trimmedSearch)
      : await getCount();

  if (
    typeof rawObj !== "object" ||
    rawObj.count === undefined ||
    rawObj.count == null
  )
    throw new ServiceError(
      "Invalid news count",
      "Server sent invalid response. Please contact administrator.",
      rawObj
    );
  const res = parseInt(rawObj.count);
  if (isNaN(res))
    throw new ServiceError(
      "Invalid news count",
      `Value ${res} is not a number.`,
      rawObj
    );
  return res;
}

export async function getNewsList(
  page?: number,
  size?: number,
  search?: string
): Promise<NewsSummary[]> {
  const trimmedSearch = search?.trim();
  const rawData =
    trimmedSearch !== undefined && trimmedSearch.length > 0
      ? await getList(page, size, trimmedSearch)
      : await getList(page, size);

  if (!Array.isArray(rawData))
    throw new ServiceError(
      "Invalid news list array",
      "Invalid news list array.",
      rawData
    );

  const arrayData: any[] = rawData;

  return arrayData.map((news) => toNewsSummary(news));
}


export async function getNewsDetail(
  name: string
): Promise<NewsDetail>{
  const rawData= await getName(name);
  

  return toNewsDetail(rawData);

}