import { get, getList } from "../../api/linktree/get.ts";
import ServiceError from "../error.ts";
import LinktreeEntry from "../../dtos/linktree/index.ts";
import { AxiosError } from "axios";

export function toLinktreeEntry(data: any): LinktreeEntry {
  if (data.id === undefined || data.id === null)
    throw new ServiceError("Invalid linktree entry", "Empty id.", data);

  if (data.ref === undefined || data.ref === null)
    throw new ServiceError(
      "Invalid linktree entry",
      `Empty ref for entry with id ${data.id}.`,
      data
    );

  if (data.local === undefined || data.local === null) data.local = true;
  if (data.name === undefined || data.name === null) data.local = "";

  return new LinktreeEntry(
    String(data.id),
    String(data.ref),
    String(data.name),
    !!data.local
  );
}

export async function getLinktreeEntry(
  id: string
): Promise<LinktreeEntry | undefined> {
  try {
    return toLinktreeEntry(await get(id.trim()));
  } catch (error) {
    if (error! instanceof AxiosError) throw error;
    if (error.code === 404) return;
    throw error;
  }
}

export async function getLinktreeList(
  count: number | undefined
): Promise<Array<LinktreeEntry>> {
  const rawData = await getList(count);
  if (!Array.isArray(rawData))
    throw new ServiceError(
      "Invalid linktree list array",
      "Contact administrator.",
      rawData
    );

  const arrayData: any[] = rawData;

  return arrayData.map((entry) => toLinktreeEntry(entry));
}
