import "./styles.css";

function SectionOportunitati({ active }) {
  return (
    <>
      {active && (
        <div className="navigation-menu-section oportunitati">oportunitati</div>
      )}
    </>
  );
}

export default SectionOportunitati;
