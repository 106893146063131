import axios from "axios";
import { SERVER_HOST } from "../config.ts";

const endpoint = SERVER_HOST;

export async function get(resources: string[] | string): Promise<any> {
  const url: string = endpoint;

  const result = (
    await axios.get(url, {
      params: { resources: JSON.stringify(resources) },
    })
  ).data;

  return result;
}
