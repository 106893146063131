import axios from "axios";
import { SERVER_HOST } from "../config.ts";

const uri: string = "/linktree.php";
const endpoint = SERVER_HOST + uri;

export async function get(id: string): Promise<any> {
  const url: string = endpoint;

  const result = (
    await axios.get(url, {
      params: { entry: JSON.stringify(id) },
    })
  ).data;

  return result;
}

export async function getList(count: number | undefined): Promise<any> {
  const url: string = endpoint;

  const result = (
    await axios.get(url, {
      params: {
        list: "",
        ...(count === undefined ? {} : { count: JSON.stringify(count) }),
      },
    })
  ).data;

  return result;
}
