import "./styles.css";
import React, { useState } from "react";

export default function ButtonClipboardCopy({
  children,
  text,
  title,
  className,
  ...others
}) {
  const [copied, setCopied] = useState(false);
  return (
    <button
      className={"button-clipboard-copy" + (className ? " " + className : "")}
      onClick={() => {
        window.navigator.clipboard.writeText(text);
        setCopied(true);
      }}
      onMouseEnter={() => setCopied(false)}
    >
      <span className="label">
        {copied ? title + " copied to clipboard" : "Copy to clipboard"}
      </span>
      {children}
    </button>
  );
}
