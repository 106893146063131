import Resource from "../resource/index.ts";
import StructureGroup from "./group.ts";

class Structure {
  id: string;
  name: string;
  nameShort: string;
  url?: string;
  logoSimpleWhite?: string;
  logoSimpleColor?: string;
  logoText: Resource;
  color: number;
  group: StructureGroup;

  constructor(
    id: string,
    name: string,
    nameShort: string,
    url: string | undefined | null,
    logoSimpleWhite: string | undefined | null,
    logoSimpleColor: string | undefined | null,
    imageHost: string,
    logoText: Resource,
    color: number,
    group: StructureGroup
  ) {
    this.id = id;
    this.name = name;
    this.url =
      url === undefined || url === null || url === "null" ? undefined : url;
    this.logoSimpleWhite =
      logoSimpleWhite === undefined ||
      logoSimpleWhite === null ||
      logoSimpleWhite === "null"
        ? undefined
        : imageHost + logoSimpleWhite;
    this.logoSimpleColor =
      logoSimpleColor === undefined ||
      logoSimpleColor === null ||
      logoSimpleColor === "null"
        ? undefined
        : imageHost + logoSimpleColor;
    this.nameShort = nameShort;
    this.logoText = logoText;
    this.color = color;
    this.group = group;
  }
}

export default Structure;
