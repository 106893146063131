import { useLoaderData, useRouteError } from "react-router-dom";
import GenericErrorDisplay from "../../generic-error/index.jsx";
import "./styles.css";
import { getResources } from "../../../service/resource/index.ts";
import { useEffect, useRef, useState } from "react";
import ResourceDisplay from "../../../components/resource-display/index.jsx";
import ButtonPrimary from "../../../components/button-primary/index.tsx";
import { sendEmail } from "../../../service/form230/index.ts";
import { SyncLoader } from "react-spinners";
import STYLES_CONSTANTS from "../../../styles/index.js";

const resourceKeys = {
  cover: "support-230-cover",
  subtitle: "support-230-subtitle",
  fillTitle: "support-230-fill-title",
  sendTitle: "support-230-send-title",
};

export const path = "form230/";

export async function loader({ request }) {
  return await getResources(Object.values(resourceKeys));
}

export function Component() {
  const resourcesArray = useLoaderData();

  const [cover, setCover] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [fillTitle, setFillTitle] = useState(null);
  const [sendTitle, setSendTitle] = useState(null);
  useEffect(() => {
    if (
      resourcesArray === undefined ||
      resourcesArray === null ||
      !Array.isArray(resourcesArray)
    ) {
      setCover(null);
      setSubtitle(null);
      setFillTitle(null);
      setSendTitle(null);
      return;
    }

    resourcesArray.forEach((resource) => {
      if (resource.id === resourceKeys.cover) setCover(resource);
      if (resource.id === resourceKeys.subtitle) setSubtitle(resource);
      if (resource.id === resourceKeys.fillTitle) setFillTitle(resource);
      if (resource.id === resourceKeys.sendTitle) setSendTitle(resource);
    });
  }, [resourcesArray]);

  const [color, setColor] = useState(null);

  const formRef = useRef();
  const uploaderRef = useRef();
  const [resultMessage, setResultMessage] = useState(undefined);

  async function handleFormSubmit(e) {
    e.preventDefault();

    const data = new FormData(formRef.current);
    data.append("form", uploaderRef.current.name);
    setResultMessage(null);
    const [success, message] = await sendEmail(data);
    setColor(success ? "green" : "red");
    setResultMessage(message);
  }

  return (
    <div id="form230">
      <header>
        <ResourceDisplay resource={cover} />
        <div
          style={{
            height: "var(--gap-medium)",
            backgroundColor: "var(--color-fasmr-blue)",
          }}
        />
      </header>
      <div className="content content-container">
        <ResourceDisplay resource={subtitle} wrapper="h4" />
        <div className="card">
          <ResourceDisplay resource={fillTitle} wrapper="h2" />
          <iframe
            title="fill-form"
            src="https://formular230.ro/federatia-asociatiilor-studentilor-in-medicina-din-romania?fbclid=IwAR3JZlPL01kwFwLDdmt6VZuznNSI2QgyNl9E5vGXx2KPAnS4Z9tnnaSdnzQ"
          />
        </div>
        <div className="card send">
          <ResourceDisplay resource={sendTitle} wrapper="h2" />
          <form ref={formRef}>
            <label for="name">Nume</label>
            <input type="text" name="name" id="form-230-name" />
            <label for="email">E-mail</label>
            <input type="text" name="email" id="form-230-email" />
            <label for="file">Formular</label>
            <input
              type="file"
              name="file"
              id="form-230-file"
              ref={uploaderRef}
            />
            <label for="file">GDPR</label>
            <div>
              <input type="checkbox" name="gdpr" id="form-230-gdpr" />
              Sunt de acord cu prelucrarea datelor
            </div>
            <div />
            <ButtonPrimary onClick={handleFormSubmit} className="submit">
              Trimite
            </ButtonPrimary>
            <div />
            <div style={{ color }}>
              {resultMessage === null ? (
                <SyncLoader color={STYLES_CONSTANTS["--color-fasmr-blue"]} />
              ) : (
                resultMessage
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
Component.displayName = "page-sustine-230";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-sustine-230-error";
