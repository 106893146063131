import bulletpointNeuron from "../../../images/bulletpoint-neuron.svg";

function LeftListItem({
  children,
  href,
  active = false,
  setActive = () => {},
}) {
  return (
    <div
      className={"left-list-item" + (active ? " active" : "")}
      // to={href}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      {active && (
        <>
          <div className="pseudo-border top-right">
            <div className="overlay top-right" />
          </div>
          <div className="pseudo-border bottom-right">
            <div className="overlay bottom-right" />
          </div>
        </>
      )}
      <div className="icon-container">
        <img src={bulletpointNeuron} alt="" className="h" />
      </div>
      {children}
    </div>
  );
}

export default LeftListItem;
