import axios from "axios";
import { SERVER_HOST } from "../config.ts";

const uri: string = "/news.php";
const endpoint: string = SERVER_HOST + uri;

export async function getCount(search?: string): Promise<any> {
  const url: string = endpoint;

  let params: any =
    search === undefined ? { count: "" } : { count: "", search };

  const result = (await axios.get(url, { params })).data;

  return result;
}

export async function getList(
  page?: number,
  size?: number,
  search?: string
): Promise<any> {
  const url: string = endpoint;

  let params: any = search === undefined ? { list: "" } : { list: "", search };

  if (page !== undefined && page !== null) params = { page, ...params };
  if (size !== undefined && size !== null) params = { size, ...params };

  const result = (await axios.get(url, { params })).data;

  return result;
}

export async function getName(name?: string ): Promise<any>{
    const params: any={name:JSON.stringify(name)};
    const endpoint: string = SERVER_HOST + uri;
    const result=(await axios.get(endpoint,{params})).data;
    return result;
}   

