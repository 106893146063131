import { Outlet, Route } from "react-router-dom";
import * as DespreNoiIndex from "./page-despre-noi-index";
import * as Comitete from "./pages-comitete";
import * as Proiecte from "./pages-proiecte";
import * as Divizii from "./page-divizii";
import * as Asociatii from "./page-asociatii";
import * as CD from "./page-cd";
import * as Galerie from "./page-galerie";

export const path = "/about/";

export function Component() {
  return <Outlet />;
}
Component.displayName = "pages-despre-noi";

export const children = [
  <Route index {...DespreNoiIndex} />,
  <Route {...Comitete} />,
  <Route {...Proiecte} />,
  <Route {...Divizii} />,
  <Route {...Asociatii} />,
  <Route {...CD} />,
  <Route {...Galerie} />,
];
