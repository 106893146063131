import ResourceDisplay from "../../../../components/resource-display";
import "./styles.css";

export const id = "description";
function SectionDescriere({ title, text, logo }) {
  return (
    <div id={id} className="container content-container">
      <div className="text-container">
        <ResourceDisplay resource={title} wrapper="h1" />
        <div className="text">
          <ResourceDisplay resource={text} />
        </div>
      </div>
      <div className="image-container">
        <ResourceDisplay resource={logo} className="image" />
      </div>
    </div>
  );
}

export default SectionDescriere;
