import Resource from "../resource/index.ts";
import NewsSummary from "./summary.ts";

class NewsDetail extends NewsSummary {
  back_img: Resource;
  facebook: string;
  instagram: string;
  hashtags: string[];
  related: NewsSummary[];

  constructor(
    id: number,
    name: string,
    title: string,
    content: string,
    date: Date,
    front_img: Resource,
    hidden: boolean = false,
    back_img: Resource,
    facebook: string,
    instagram: string,
    hashtags: string[],
    related: NewsSummary[]
  ) {
    super(id, name, title, content, date, front_img, hidden);
    this.back_img = back_img;
    this.facebook = facebook;
    this.instagram = instagram;
    this.hashtags = hashtags;
    this.related = related;
  }
}
export default NewsDetail;
