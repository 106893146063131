import { Outlet, Route } from "react-router-dom";
import * as SupportIndex from "./page-sustine-index";
import * as Form230 from "./page-sustine-230";

export const path = "/support/";

export function Component() {
  return <Outlet />;
}
Component.displayName = "pages-sustine";

export const children = [
  <Route index {...SupportIndex} />,
  <Route {...Form230} />,
];
