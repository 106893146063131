import ResourceType from './type';

class Resource {
    id: string;
    type: ResourceType;
    value: string;
    constructor(id: string, type: ResourceType, value: string) {
        this.id = id;
        this.type = type;
        this.value = value;
    }
}

export default Resource;