import { getGroup } from "../../api/structure/get.ts";
import ServiceError from "../error.ts";
import { IMAGE_HOST } from "../../api/config.ts";
import Structure from "../../dtos/structure/index.ts";
import StructureGroup from "../../dtos/structure/group.ts";
import { toResource } from "../resource/index.ts";

export function toStructure(structure: any): Structure {
  if (structure.id === undefined || structure.id === null)
    throw new ServiceError("Invalid structure", "Empty id.", structure);

  if (structure.name === undefined || structure.name === null)
    throw new ServiceError(
      "Invalid structure",
      `Empty name for structure ${structure.id}.`,
      structure
    );

  if (structure.name_short === undefined || structure.name_short === null)
    throw new ServiceError(
      "Invalid structure",
      `Empty name_short for structure ${structure.id}.`,
      structure
    );

  if (structure.logo_text === undefined || structure.logo_text === null)
    throw new ServiceError(
      "Invalid structure",
      `Empty logo_text for structure ${structure.id}.`,
      structure
    );
  const logo_text = toResource(structure.logo_text);

  if (
    structure.color === undefined ||
    structure.color === null ||
    structure.color! instanceof Number
  )
    throw new ServiceError(
      "Invalid structure",
      `Empty color for structure ${structure.id}.`,
      structure
    );

  const group = StructureGroup[String(structure.group)];
  if (group === undefined || group === null)
    throw new ServiceError(
      "Invalid structure",
      `Empty group for structure ${structure.id}.`,
      structure
    );

  return new Structure(
    String(structure.id),
    String(structure.name),
    String(structure.name_short),
    structure.url,
    structure.logo_simple_white,
    structure.logo_simple_color,
    IMAGE_HOST,
    logo_text,
    structure.color,
    group
  );
}

export async function getStructureGroup(group: string): Promise<Structure[]> {
  const rawData: any = await getGroup(group);
  if (!Array.isArray(rawData))
    throw new ServiceError(
      "Invalid structure array",
      "Invalid structure array.",
      rawData
    );

  return rawData.map((structure) => toStructure(structure));
}
