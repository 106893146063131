import { useNavigate } from "react-router-dom";
import Resource from "../../dtos/resource";
import ButtonPrimarySide from "../button-primary-side/index.tsx";
import ResourceDisplay from "../resource-display";
import "./styles.css";
import React from "react";
import STYLES_CONSTANTS from "../../styles/index.js";

type propsType = {
  title: Resource | null;
  text: Resource | null;
  url: string;
  icon: React.ReactNode;
  titleWrapper: string;
  mirror: boolean;
};

function CardInformationBig({
  title,
  text,
  url = "/",
  icon,
  titleWrapper = "h2",
  mirror = false,
}: propsType) {
  const navigate = useNavigate();

  return (
    <div className={"card-information-big" + (mirror ? " mirror" : "")}>
      <ResourceDisplay resource={title} wrapper={titleWrapper} />
      <ResourceDisplay resource={text} className="text" />
      <div className="last-row">
        <div className="short-content">
          <div className="button">
            <ButtonPrimarySide
              title="Află mai multe"
              onClick={() => navigate(url)}
              side={mirror ? "left" : "right"}
            />
          </div>
          <div className="lines">
            <div className="top" />
            <svg
              width="50px"
              viewBox="0 0 50 50"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path
                fill="none"
                stroke={STYLES_CONSTANTS["--color-fasmr-blue"]}
                strokeWidth={1}
                d="M 0,0 L 50,50"
              />
            </svg>
            <div className="bottom" />
          </div>
        </div>
        <div className="icons">{icon}</div>
      </div>
    </div>
  );
}

export default CardInformationBig;
