import Person from "../../dtos/person/index.ts";
import "./styles.css";
import React from "react";

type propsType = {
  person: Person;
  className?: string;
};

function CardPerson({ person, className = "", ...others }: propsType) {
  return (
    <div
      className={"card-person" + (className === "" ? "" : " " + className)}
      {...others}
    >
      <div className="profile">
        <img src={person.profileLocation} alt={person.id} />
      </div>
      <div className="text">
        <div className="title">{person.name}</div>
        <div className="label">{person.position}</div>
      </div>
    </div>
  );
}

export default CardPerson;
