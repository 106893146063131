import axios from "axios";
import { SERVER_HOST } from "../config.ts";

const uri: string = "/contact.php";
const endpoint = SERVER_HOST + uri;

export async function post(data: FormData) {
  const url: string = endpoint;

  const result = await axios({
    method: "post",
    url: url,
    data: data,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return result;
}
