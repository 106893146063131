import { Link } from "react-router-dom";
import { path as SustinePath } from "../../pages/page-sustine";

/**
 * The button on the right of the navbar.
 *
 * @param {*} children Button content
 * @param {function} onClick What to do when the button is clicked.
 * @returns The react component
 */
function RightButton({ onclick, children }) {
  const styles = {
    container: {
      height: "100%",

      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",

      borderRadius: "var(--button-height) 0 0 var(--button-height)",
      borderRight: "none",

      paddingLeft: "calc(var(--button-height) * 0.6)",
      paddingRight: "var(--gap-small)",
      gap: "var(--gap-small)",

      fontWeight: "bold",
      fontSize: "1.1em",

      textDecoration: "none",
    },
  };
  return (
    <Link
      to={SustinePath}
      className="fill-button right-button"
      style={styles.container}
    >
      {children}
    </Link>
  );
}

export default RightButton;
