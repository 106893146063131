function Separator({ vertical, color, space, style, ...props }) {
  return (
    <div
      style={{
        ...{
          alignSelf: "stretch",
          backgroundColor: color ? color : "var(--color-dark)",
        },
        ...(vertical
          ? {
              width: "1px",
              margin: (space ? space : "var(--gap-medium)") + " 0",
            }
          : {
              height: "1px",
              margin: "0 " + (space ? space : "var(--gap-medium)"),
            }),
        ...style,
      }}
      {...props}
    />
  );
}

export default Separator;
